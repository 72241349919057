import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, FormLabel, FormControl} from '@material-ui/core';


const checkbox = (props) => {
  const {
    label, classes, value, onChange, allowed_values, color
  } = props;

  let checkboxes = allowed_values.map((item, idx) => (
    <FormControlLabel
      key={`chkb${item.label}${item.value}${idx}`}
      className={classes.input}
      control={
        <Checkbox
          checked={value && value.indexOf(item.value) !== -1}
          onChange={e => onChange(e, item.value)}
          value={`${item.value}`}
          color={color}
        />
      }
      label={item.label}
    />
  ));

  if (checkboxes.length > 1) {
    checkboxes = (
      <FormGroup row >
        {checkboxes}
      </FormGroup>
    );
  }

  if (label) {
    return (
      <FormControl margin="dense" fullWidth>
        <FormLabel component="legend" className={classes.label}>{label}</FormLabel>
        {checkboxes}
      </FormControl>
    );
  }
  return (
    <FormControl fullWidth>
      {checkboxes}
    </FormControl>
  );
};

export default checkbox;
