import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import { ChromePicker } from 'react-color';

class Color extends Component {

  state = {
    anchorEl: null,
  }

  handleChange = (color) => {
    const event = {target: {value: color.hex}};
    this.props.onChange(event);
  };

  handleClick = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      label, validators, placeholder, classes, reference, editor, InputLabelProps,
      input, raw_attributes: rawAttributes, value, ...rest
    } = this.props;

    const val = value || '#000';

    return (
      <React.Fragment>
        <TextField
          label={label}
          placeholder={placeholder}
          InputLabelProps={{ className: classes.label, ...InputLabelProps }}
          InputProps={{
            style: {
              backgroundColor: val,
              color: '#fff',
            },
            inputProps: {
              style: {
                textAlign: 'Center'
              }
            },
            disabled: true
          }}
          FormHelperTextProps={{ component: 'span' }}
          fullWidth
          value={val}
          onClick={this.handleClick}
          type="Text"
          {...rest}
          {...rawAttributes}
        />
        {this.state.anchorEl ?
          <Popover
            open
            onClose={this.handleClose}
            anchorEl={this.state.anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <ChromePicker disableAlpha color={val} onChange={this.handleChange} />
          </Popover>
          : null}
      </React.Fragment>
    );
  }
}

export default Color;
