import * as actionTypes from './ActionTypes';

export function resetFilters() {
  return { type: actionTypes.RESET_FILTERS };
}

export const setFilters = filters => ({
  type: actionTypes.SET_FILTERS,
  payload: filters
});

export const setPageSize = pageSize => ({
  type: actionTypes.SET_PAGE_SIZE,
  payload: pageSize
});
