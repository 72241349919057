import React from 'react';
import APPCONFIG from 'constants/Config';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import QueueAnim from 'rc-queue-anim';
import Form from 'components/UI/Form/Form';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import * as actions from '../../store/actions';
import axios from '../../axios-instance';

class Login extends React.Component {

  constructor() {
    super();
    this.state = {
      currentForm: 'login',
      login: {
        fields: {
          login: {
            name: 'login',
            id: 'login',
            editor: 'text',
            placeholder: '',
            value: '',
            label: 'Identifiant',
            classes: { wrapper: 'col-12 form-group' },
            validators: [{ name: 'required', params: true }]
          },
          password: {
            name: 'password',
            id: 'password',
            editor: 'password',
            value: '',
            label: 'Mot de passe',
            classes: { wrapper: 'col-12 form-group' },
            validators: [{ name: 'required', params: true }]
          }
        },
        showPlaceholder: true,
        class: 'form-horizontal'
      },
      reset: {
        fields: {
          login: {
            name: 'login',
            id: 'login',
            editor: 'email',
            placeholder: '',
            value: '',
            label: 'Adresse e-mail',
            classes: { wrapper: 'col-12 form-group' },
            validators: [{ name: 'required', params: true }]
          }
        },
        showPlaceholder: true,
        class: 'form-horizontal'
      }
    };
  }

  onSubmit = (datas) => {
    if (this.props.location.hash === '#reset') {
      axios.post('Auth/reset', datas).then((response) => {
        this.setState({resetMessage: response.data.response.message});
      });
    } else {
      this.props.onAuth(datas);
    }
  }

  toogleForm = () => {
    this.setState(prevState => ({ currentForm: prevState.currentForm === 'login' ? 'lost' : 'login' }));
  }

  renderAdditionalInfos = (currentForm) => {
    if (this.props.loading) {
      return (<div className="additional-info" />);
    }
    return (
      <div className="additional-info">
        {currentForm !== 'reset' ?
          <a href="#reset">Mot de passe oublié ?</a>
        : null}
      </div>
    );
  }

  render() {
    const logoStyle = {
      maxHeight: '100px',
      maxWidth: '100%'
    };

    const currentForm = this.props.location.hash === '#reset' ? 'reset' : 'login';

    // console.log(this.state[currentForm]);

    return (
      <div className="page-login">
        <div className="main-body">
          <QueueAnim type="bottom" className="ui-animate">
            <div key="1">
              <div className="body-inner">
                <div className="card bg-white">
                  <div className="card-content">
                    <section className={`logo text-center ${APPCONFIG.brandLogo && APPCONFIG.brandLogo.big ? 'mb-5' : ''}`}>
                      <Link to="/">
                        {APPCONFIG.brandLogo && APPCONFIG.brandLogo.big
                          ? <h1><img alt="logo" style={logoStyle} src={APPCONFIG.brandLogo.big} /></h1>
                          : <h1>{APPCONFIG.brand}</h1>
                        }
                      </Link>
                      {this.props.loading ? <CircularProgress /> : null}
                    </section>
                    {this.props.axiosError ? (
                      <Typography component="span" align="justify" color="error">
                        {(this.props.axiosError.code === 'KO' || this.props.axiosError.code === 'KO-VALIDATION') ? this.props.axiosError.message : null}
                      </Typography>
                    ) : null}
                    {this.state.resetMessage && currentForm === 'reset' ? (
                      <Typography component="span" align="justify" color="textPrimary">
                        {this.state.resetMessage}
                      </Typography>
                    ) : (
                      <Form
                        form={this.state[currentForm]}
                        handleSubmit={this.onSubmit}
                        key={currentForm}
                        ref={(ref) => { this.form = ref; }}
                        className={this.props.loading ? 'd-none' : ''}
                        error={this.props.axiosError}
                      />
                    )}
                  </div>
                  <div className="card-action no-border text-right">
                    {currentForm === 'reset' ?
                      <Button color="secondary" href="#">Retour</Button>
                    : null}
                    {!this.props.loading && !(this.state.resetMessage && currentForm === 'reset') ?
                      <Button color="primary" type="submit" onClick={e => this.form.clickSubmit(e)}>
                        {currentForm === 'reset' ? 'Envoyer' : 'Se connecter'}
                      </Button>
                      : null}
                  </div>
                </div>
                {this.renderAdditionalInfos(currentForm)}
              </div>
            </div>
          </QueueAnim>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.auth.loading,
  isAuthenticated: state.auth.token !== null,
  axiosError: state.axios.error
});

const mapDispatchToProps = dispatch => ({
  onAuth: formData => dispatch(actions.auth(formData, axios))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
