import React from 'react';
import { ValidatorForm } from '../../../utils/validators';

const SimpleForm = (props) => {
  const jsxForm = props.buildForm();
  const classes = props.className !== 'modal-form' ? `form-row ${props.className}` : '';
  return (
    <ValidatorForm ref={props.formRef} className={classes} onSubmit={props.handleSubmit}>
      {jsxForm}
    </ValidatorForm>
  );
};

export default SimpleForm;
