import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Page500 from '../../containers/500/500';
import * as actions from '../../store/actions';

const mapStateToProps = state => ({
  isCheckingAuth: state.auth.checking
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(actions.logout()),
  setAxiosError: error => dispatch(actions.setAxiosError(error))
});

const AxiosErrorHandler = (WrappedComponent, axios, global = false) =>
  withRouter(connect(mapStateToProps, mapDispatchToProps)(class extends Component {
    state = {
      error: null,
      message: null
    };

    componentDidMount() {
      this.reqInterceptor = axios.interceptors.request.use((request) => {
        this.props.setAxiosError(null);
        return request;
      }, (error) => {
        // console.log(error);
        this.onError({...error.response.data});
        return Promise.reject(error);
      });
      // eslint-disable-next-line arrow-body-style
      this.resInterceptor = axios.interceptors.response.use((res) => {
        // console.log(res);
        return res;
      }, (error) => {
        // console.log(error);
        if (error && error.response && error.response.data) {
          this.onError({...error.response.data});
        }
        return Promise.reject(error);
      });
    }

    componentWillUnmount() {
      axios.interceptors.response.eject(this.resInterceptor);
      axios.interceptors.request.eject(this.reqInterceptor);
    }

    onError = (e) => {
      if (!e || e === this.state.error) {
        return;
      }

      switch (e.code) {
        case 'KO-NETWORK':
          this.setState({
            message: 'Erreur de communication avec le serveur, veuillez verifier votre connection avec internet.',
            error: e
          });
          break;
        case 'KO-AUTH':
          if (!this.props.isCheckingAuth) {
            this.setState({
              message: 'Votre session a expiré, vous allez être redirigé vers la page d\'acceuil',
              error: e
            });
          }
          break;
        case 'KO':
          this.onKOCode(e);
          break;
        default:
          /*
          this.setState({
            message: null,
            error: e
          });
          */
          this.props.setAxiosError(e);
      }
    }

    onKOCode = (e) => {
      switch (e.status) {
        case 500:
          this.setState({
            error: e
          });
          break;
        case 404:
          this.props.history.push('/404');
          break;
        default:
          this.setState({ error: e });
          this.props.setAxiosError(e);
      }
    }

    handleClose = () => {
      switch (this.state.error.code) {
        case 'KO-AUTH':
          this.props.logout();
          this.props.history.push('/');
          this.setState({ message: null });
          break;
        default:
          this.setState({ message: null });
          break;
      }
    };

    render() {
      if (this.state.error && this.state.error.status === 500) {
        return (<Page500 />);
      }
      return (
        <React.Fragment>
          <Dialog
            open={this.state.message !== null && global}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Erreur</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <WrappedComponent {...this.props} />
        </React.Fragment>
      );
    }
  }));

export default AxiosErrorHandler;
