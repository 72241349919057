import * as actionTypes from '../actions/ActionTypes';

const initialState = {
  token: null,
  loading: null,
  userInfos: null,
  checking: null,
  navigation: null,
  apps: null,
  shortcuts: null,
  headerInfos: null,
  activPath: '',
};

const authStart = (state, payload) => ({...state, loading: true});
const authSucces = (state, payload) => ({
  ...state, loading: false, token: payload.token, userInfos: payload.userInfos
});
const authFail = (state, payload) => ({...state, token: null, loading: false});
const authLogout = (state, payload) => ({...initialState, loading: false, checking: false});
const authCheckStart = (state, payload) => ({...state, checking: true});
const authCheckSucces = (state, payload) => ({
  ...state,
  checking: false,
  token: payload.authToken,
  navigation: payload.navigation,
  userInfos: payload.user_infos,
  apps: payload.apps,
  shortcuts: payload.shortcuts,
  headerInfos: payload.header
});
const authCheckFail = (state, payload) => ({...state, checking: false});
const authActivPath = (state, payload) => ({...state, activPath: payload.path});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action.payload);
    case actionTypes.AUTH_SUCCESS: return authSucces(state, action.payload);
    case actionTypes.AUTH_FAIL: return authFail(state, action.payload);
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action.payload);
    case actionTypes.AUTH_CHECK_START: return authCheckStart(state, action.payload);
    case actionTypes.AUTH_CHECK_SUCCESS: return authCheckSucces(state, action.payload);
    case actionTypes.AUTH_CHECK_FAIL: return authCheckFail(state, action.payload);
    case actionTypes.AUTH_ACTIV_PATH: return authActivPath(state, action.payload);
    default:
      return state;
  }
};

export default reducer;
