import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

const ModalLoading = () => (
  <Dialog
    open
    aria-labelledby="loading-dialog"
  >
    <DialogContent>
      <CircularProgress size={50} />
    </DialogContent>
  </Dialog>
);

export default ModalLoading;
