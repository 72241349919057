import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { TextField, InputBase } from '@material-ui/core/';

class HiddenText extends Component {

  render() {
    const {
      id, label, validators, placeholder, classes, reference, editor, InputLabelProps,
      input, raw_attributes: rawAttributes, value, disabled, allowed_values: allowedValues, ...rest
    } = this.props;

    let val = value;

    if (val === null) {
      val = undefined;
    }

    if (val === undefined) {
      return null;
    }

    let foundAllowedValue = null;
    if (allowedValues.length > 0) {
      foundAllowedValue = allowedValues.find(item => item.value === val);
      if (foundAllowedValue && foundAllowedValue.label) {
        val = foundAllowedValue.label;
      }
    }

    this.input = input;
    let InputProps = { className: classes.input, disableUnderline: true, readOnly: true };
    if (editor && editor.toLowerCase() === 'hidden') {
      return null;
    }

    if (rawAttributes && rawAttributes.mask) {
      InputProps = {
        ...InputProps,
        inputComponent: InputMask,
        inputProps: { maskChar: rawAttributes.maskChar, mask: rawAttributes.mask },
      };
    }
    return (
      foundAllowedValue ?
        <React.Fragment>
          <InputBase type="hidden" value={val} {...rest} />
          <TextField
            label={label}
            placeholder={placeholder}
            multiline
            InputLabelProps={{ className: classes.label, ...InputLabelProps }}
            InputProps={InputProps}
            fullWidth
            value={val}
            type="text"
            disabled={disabled}
        />
        </React.Fragment>
        :
        <TextField
          label={label}
          placeholder={placeholder}
          multiline
          InputLabelProps={{ className: classes.label, ...InputLabelProps }}
          InputProps={InputProps}
          fullWidth
          value={val}
          type="text"
          disabled={disabled}
          {...rest}
          {...rawAttributes}
        />
    );
  }
}

export default HiddenText;
