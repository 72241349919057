import { ValidationRules } from 'react-form-validator-core';
import { ValidatorForm as _ValidatorForm } from 'react-material-ui-form-validator';

const getValidators = (fieldValidators, field) => fieldValidators.reduce((prev, validator) => {
  const props = { ...prev };
  // console.log(field);
  if (validator.name) {
    switch (validator.name) {
      case 'required':
        if (validator.params) {
          props.validators.push('required');
          props.errorMessages.push('Champs requis');
          props.required = true;
        }
        break;
      case 'Email':
        if (validator.params) {
          props.validators.push('isEmail');
          props.errorMessages.push('Adresse email invalide');
        }
        break;
      case 'Numeric':
        if (validator.params) {
          props.validators.push('isNumeric');
          props.errorMessages.push('n\'est pas un nombre');
          props.type = 'number';
        }
        break;
      case 'AllowedValues':
        /*
          if (validator.params) {
            const allowedValues = field.allowed_values || field.allowedValues;
            const regex = allowedValues.reduce((prev, current) => (
              prev ? `${prev}|(${current.value})` : `(${current.value})`
            ), false);
            props.validators.push(`matchRegexp:((^(${regex})$)|^$)`);
            props.errorMessages.push('invalide');
          }
        */
        break;
      case 'Regexp':
        if (validator.params && validator.params.regexp) {
          props.validators.push(`matchRegexp:${validator.params.regexp.replace(/^\//g, '').replace(/\/$/g, '')}`);
          props.errorMessages.push('invalide');
        }
        break;
      case 'LengthRange':
        if (validator.params && validator.params.min !== undefined && validator.params.min !== null) {
          props.validators.push(`minLength:${validator.params.min}`);
          props.errorMessages.push(`Minimum ${validator.params.min} caractère`);
        }
        if (validator.params && validator.params.max !== undefined && validator.params.max !== null) {
          props.validators.push(`maxLength:${validator.params.max}`);
          props.errorMessages.push(`Maximum ${validator.params.max} caractère`);
        }
        break;
      case 'ValueRange':
        if (validator.params && validator.params.min !== undefined && validator.params.min !== null) {
          props.validators.push(`minNumber:${validator.params.min}`);
          props.errorMessages.push(`Minimum : ${validator.params.min}`);
        }
        if (validator.params && validator.params.max !== undefined && validator.params.max !== null) {
          props.validators.push(`maxNumber:${validator.params.max}`);
          props.errorMessages.push(`Maximum : ${validator.params.max}`);
        }
        break;
      default:
        // console.log(`undefined validator ${validator.name}`);
        return props;
    }
  }
  return props;
}, { validators: [], errorMessages: [], required: false });

_ValidatorForm.addValidationRule('isNumeric', value => ValidationRules.matchRegexp(value, /^-?(?:[1-9]\d*|0)?(?:\.\d+)?$/i));
_ValidatorForm.addValidationRule('minLength', (value, length) => ValidationRules.isEmpty(value) || ValidationRules.minStringLength(value, length));
_ValidatorForm.addValidationRule('maxLength', (value, length) => ValidationRules.isEmpty(value) || ValidationRules.maxStringLength(value, length));

export default getValidators;
export { _ValidatorForm as ValidatorForm };
