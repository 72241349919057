import React from 'react';
import SidenavMenu from './SidenavMenu/SidenavMenu';

class SidebarContent extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (!props.menu || props.menu === state.menu) {
      return state;
    }

    return {...state, menu: props.menu};
  }

  state = {
    menu: []
  };

  componentDidMount() {
    /*
    axios.get('/menu.json').then((rep) => {
      this.setState({menu: rep.data});
    }).catch((error) => { });
    */
  }

  render() {
    return (this.state.menu && this.state.menu.length > 0 ?
      <SidenavMenu
        menu={this.state.menu}
      />
      : null
    );
  }
}

export default SidebarContent;
