import React from 'react';
import { FormControl, FormLabel } from '@material-ui/core';

const Fieldset = props => (
  <FormControl fullWidth className={props.classes.div || props.class} component="fieldset">
    {props.label && props.label.length > 0 ? <FormLabel component="legend" className={props.classes.label} >{props.label}</FormLabel> : null}
    {props.classes.wrapper && props.classes.wrapper.length > 0 ?
      <div className={props.classes.wrapper}>
        {props.children}
      </div>
    : props.children}
  </FormControl>
);

Fieldset.defaultProps = {
  classes: { label: '', div: '', wrapper: '' }
};

export default Fieldset;
