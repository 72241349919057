import React, { Component } from 'react';
import { InputAdornment, IconButton } from '@material-ui/core/';
import { VisibilityOff, Visibility, Clear } from '@material-ui/icons/';
import { TextValidator } from 'react-material-ui-form-validator';
import InputMask from 'react-input-mask';
import dateFormat from 'dateformat';
import getValidators from '../../../../../utils/validators';

class Input extends Component {

  state = { showPassword: false }

  handleClickShowPassword = () => {
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
    if (this.props.input) {
      this.props.input.focus();
    }
  }

  render() {
    const {
      label, validators, placeholder, classes, reference, editor, InputLabelProps,
      input, raw_attributes: rawAttributes, value, ...rest
    } = this.props;

    let val = value;

    if (val === null || val === undefined) {
      val = '';
    }

    this.input = input;
    let InputProps = { className: classes.input };
    if (editor && editor.toLowerCase() === 'hidden') {
      return null;
    }
    if (editor && editor.toLowerCase() === 'date') {
      val = dateFormat(new Date(val), 'yyyy-mm-dd');
    }

    if (editor && editor.toLowerCase() === 'password') {
      InputProps = {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={this.handleClickShowPassword}
              onMouseDown={this.handleMouseDownPassword}
            >
              {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        className: classes.input
      };
    } else if (this.props.id && this.props.id.indexOf('f_filters') !== -1 && val && val.length > 0) {
      InputProps = {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Clear field"
              onClick={e => this.props.onChange({...e, target: {...e.target, value: ''}})}
              onMouseDown={e => this.props.onChange({...e, target: {...e.target, value: ''}})}
            >
              <Clear />
            </IconButton>
          </InputAdornment>
        ),
        className: classes.input
      };
    } else if (rawAttributes && rawAttributes.end_adornment) {
      InputProps = {
        ...InputProps,
        endAdornment: (<InputAdornment position="end">{rawAttributes.end_adornment}</InputAdornment>)
      };
    } else if (rawAttributes && rawAttributes.start_adornment) {
      InputProps = {
        ...InputProps,
        startAdornment: (<InputAdornment position="start">{rawAttributes.start_adornment}</InputAdornment>)
      };
    }

    if (rawAttributes && rawAttributes.mask) {
      InputProps = {
        ...InputProps,
        inputComponent: InputMask,
        inputProps: { maskChar: rawAttributes.maskChar, mask: rawAttributes.mask }
      };
    }

    const validatorProps = validators ? getValidators(validators, this.props) : {};

    return (
      <TextValidator
        label={label}
        placeholder={placeholder}
        multiline={editor && editor.toLowerCase() === 'textarea'}
        InputLabelProps={{ className: classes.label, ...InputLabelProps }}
        FormHelperTextProps={{component: 'span'}}
        InputProps={InputProps}
        fullWidth
        value={val}
        type={editor && editor.toLowerCase() === 'password' && this.state.showPassword ? 'text' : editor}
        {...rest}
        {...rawAttributes}
        {...validatorProps}
      />
    );
  }
}

export default Input;
