import React from 'react';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';

import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';

const ImgIconButtonStyle = {
  width: '60px',
  height: '60px'
};

class NavRightList extends React.Component {

  state = {
    anchorEl: null
  };

  handleClick = (value) => {
    this.handleClose();
    this.props.history.push(value);
  }

  handleToggle = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const { anchorEl } = this.state;
    return (
      <ul className="list-unstyled float-right">
        <li style={{ marginRight: '10px' }}>
          <Typography style={{display: 'inline'}} component="span" variant="body1">{this.props.authUser.username}</Typography>
          <IconButton
            aria-owns={anchorEl ? 'long-menu' : null}
            aria-haspopup="true"
            onClick={this.handleToggle}
            style={ImgIconButtonStyle}
          >
            <PersonIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={anchorEl !== null}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
            component="div"
          >
            <MenuItem component="div" onClick={() => this.handleClick('/')} >
              <ListItemIcon>
                <i className="material-icons">home</i>
              </ListItemIcon>
              <ListItemText inset primary="Accueil" />
            </MenuItem>
            <MenuItem component="div" onClick={() => this.handleClick('/logout')} >
              <ListItemIcon>
                <i className="material-icons">forward</i>
              </ListItemIcon>
              <ListItemText inset primary="Déconnexion" />
            </MenuItem>
          </Menu>
        </li>
      </ul>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.auth.userInfos
});

export default connect(mapStateToProps)(withRouter(NavRightList));
