import React, {Component} from 'react';
import DropzoneArea from './Dropzone/DropzoneArea';
import FormControlValidator from './FormControlValidator';
import Loader from '../../../Loader/Loader';
import getValidators from '../../../../../utils/validators';

class DropzoneAreaExample extends Component {

  state = {
    loading: true,
    value: null
  }

  componentDidMount() {
    this.initValue();
  }

  initValue = () => {
    if (this.props.value && typeof this.props.value === 'string' && this.props.value.indexOf('http') === 0) {
      const file = {
        path: this.props.value
      };
      this.setState({value: {data: this.props.value, file}, loading: false});
      // const event = {target: {value: undefined}};
      // this.props.onChange(event);
    }
    this.setState({loading: false});
  }

  handleChange = (files) => {
    if (this.props.multiple) {
      const event = {target: {value: files || []}};
      this.props.onChange(event);
    } else {
      const event = {target: {value: files[0] || ''}};
      this.props.onChange(event);
    }
  }
  render() {
    const {
      validators, ...rest
    } = this.props;

    const validatorProps = validators ? getValidators(validators, this.props) : {};

    const acceptedFiles = this.props.raw_attributes.upload && this.props.raw_attributes.upload.allowed_types ? this.props.raw_attributes.upload.allowed_types : ['image/jpeg', 'image/png', 'image/bmp'];

    let filesLimit = 1;

    if (this.props.multiple) {
      filesLimit = 5;
      if (this.props.raw_attributes && this.props.raw_attributes.upload && this.props.raw_attributes.upload.files_limit) {
        filesLimit = this.props.raw_attributes.upload.files_limit;
      }
    }

    return (
      <FormControlValidator {...validatorProps} {...rest}>
        {this.state.loading ?
          <Loader />
        : <DropzoneArea
          onChange={this.handleChange}
          acceptedFiles={acceptedFiles}
          filesLimit={filesLimit}
          value={this.state.value}
          />
        }
      </FormControlValidator>
    );
  }
}

export default DropzoneAreaExample;
