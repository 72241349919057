import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { Box, BoxBody } from 'components/UI/Box';
import { Typography } from '@material-ui/core';

class Stat extends Component {
  state = { redirect: false };

  handleClick = () => {
    if (this.props.item.filters) {
      this.props.setFilters(this.props.item.filters);
    }
    this.props.history.push(this.props.item.url);
  }

  render = () => (
    <div className="col-md-4">
      <Box style={{cursor: 'pointer'}} onClick={this.handleClick}>
        <BoxBody>
          <Typography
            align="center"
            variant="body2"
          >
            {this.props.item.title}
          </Typography>
          <Typography
            align="center"
            color="primary"
            variant="h3"
          >
            {this.props.item.body}
          </Typography>
        </BoxBody>
      </Box>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  setFilters: filters => dispatch(actions.setFilters(filters))
});

export default connect(null, mapDispatchToProps)(withRouter(Stat));