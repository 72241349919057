import React, { Component } from 'react';
import QueueAnim from 'rc-queue-anim';
import axios from '../../../axios-instance';
import Stat from 'components/Dashboard/Stat';

class Dashboard extends Component {

  state = {
    infos: null,
  }

  componentDidMount() {
    this.loadDatas();
  }

  loadDatas = () => {
    if (this.state.infos) {
      return;
    }
    axios.get('/Dashboard/infos').then((rep) => {
      this.setState({ infos: rep.data.response });
    });
  }

  renderStats() {
    if (! this.state.infos || ! this.state.infos.dashboard) {
      return null;
    }

    return (
      <div className="row">
        {Object.values(this.state.infos.dashboard).map((item, index) => <Stat key={index} item={item} />)}
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid no-breadcrumbs page-dashboard">
        <QueueAnim type="bottom" className="ui-animate">
          <div className="row">
            <section className="col-md-8 col-xl-9">
              <h2 className="article-title">Tableau de bord</h2>
            </section>
          </div>
          {this.renderStats()}
        </QueueAnim>
      </div>
    );
  }
}

export default Dashboard;
