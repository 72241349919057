import React from 'react';
import { Switch, FormControlLabel, FormControl} from '@material-ui/core';


const OnOff = (props) => {
  const {
    label, classes, value, onChange, color
  } = props;

  return (
    <FormControl fullWidth>
      <FormControlLabel
        control={
          <Switch
            checked={value === 'on'}
            onChange={e => onChange(e, e.target.checked ? 'on' : 'off')}
            value={value}
            color={color || 'primary'}
            className={classes.input}
          />
        }
        label={label}
        labelPlacement="end"
      />
    </FormControl>
  );
};

export default OnOff;
