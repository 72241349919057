import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const loader = () => (
  <div style={{textAlign: 'center'}}>
    <CircularProgress style={{margin: 'auto'}} />
  </div>
);

export default loader;
