import React from 'react';
import { connect } from 'react-redux';

const style = {
  height: 45
};

const badgeStyle = {
  display: 'inline-flex',
  lineHeight: '24px',
  verticalAlign: 'middle'
};

const TopBar = props => (props.infos ? (
  <div className="bg-color-dark container-fluid" style={style}>
    <div className="d-flex justify-content-between h-100">
      <span className="align-self-center">{props.infos.name}</span>
      <div className="align-self-center text-center">
        {/*
            <span className="text-warning">15 jours d'essai gratuit restant</span>
            <br />
            <span>Ajouter une méthode de paiement</span>
            */}
        <span>{props.infos.motd}</span>
      </div>
      <span className="align-self-center">
        <div className="badge badge-pill badge-light" style={badgeStyle}>
          <i className="material-icons">person_outline</i>
          <span>{props.infos.members}</span>
        </div>
      </span>
    </div>
  </div>
) : null);

const mapStateToProps = state => ({
  infos: state.auth.headerInfos,
});

export default connect(mapStateToProps)(TopBar);
