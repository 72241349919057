import React from 'react';

export const BoxColors = {
  Transparent: 'box-transparent',
  Grey: 'box-dark',
  Dark: 'bg-color-dark',
  White: 'bg-color-white',
  Primary: 'bg-color-primary',
  Info: 'bg-color-info',
  Success: 'bg-color-success',
  Warning: 'bg-color-warning',
  Danger: 'bg-color-danger'
};

const box = (props) => {

  const {
    children, bgColor, noGutters, dark, ...rest
  } = props;

  const classes = ['box', 'row'];
  if (dark) {
    classes.push(BoxColors.Grey);
  } else if (bgColor) {
    classes.push(bgColor);
  } else {
    classes.push('box-default');
  }

  if (!noGutters) {
    classes.push('mx-1');
  }
  return (
    <div className={classes.join(' ')} {...rest} >
      {children}
    </div>
  );
};

export default box;
