export function isImage(file) {
  const fileName = file.name || file.path;
  const suffix = fileName.substr(fileName.indexOf('.') + 1).toLowerCase();
  if (suffix === 'jpg' || suffix === 'jpeg' || suffix === 'bmp' || suffix === 'png') {
    return true;
  }
  const suffixL = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
  if (suffixL === 'jpg' || suffixL === 'jpeg' || suffixL === 'bmp' || suffixL === 'png') {
    return true;
  }
  return false;
}

export function convertBytesToMbsOrKbs(filesize) {
  let size = '';
  // I know, not technically correct...
  if (filesize >= 1000000) {
    size = `${(filesize / 1000000)} megabytes`;
  } else if (filesize >= 1000) {
    size = `${(filesize / 1000)} kilobytes`;
  } else {
    size = `${filesize} bytes`;
  }
  return size;
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.replace(/^data:(.*;base64,)?/, '');
      if ((encoded.length % 4) > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = error => reject(error);
  });
}
