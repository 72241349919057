import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  tabsIndicator: {
    backgroundColor: '#000',
  },
  tabRoot: {
    fontSize: 14,
    textTransform: 'initial',
    minWidth: 72,
    marginRight: theme.spacing.unit,
    '&:hover': {
      color: '#000',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#000',
    },
    '&:focus': {
      color: '#000',
      outline: 'none'
    },
  },
  tabSelected: {},
  tabLabel: {
    fontSize: theme.typography.fontSize,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.fontSize + 2,
    },
  },
  button: {

  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

class TabbedForm extends Component {
  state = {currentTab: 0};

  handleTabChange = (event, value) => {
    this.setState({ currentTab: value });
  };

  render() {

    const { classes, form, buildForm } = this.props;

    const jsxForm = buildForm(field => field.tab === this.state.currentTab);

    return (
      <form className={classnames('form-row', this.props.className)} onSubmit={this.props.handleSubmit}>
        <Tabs
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
          value={this.state.currentTab}
          onChange={this.handleTabChange}
          variant="scrollable"
          scrollButtons="on"
        >
          {this.props.form.tabs.map((tab, idx) => <Tab key={idx} className={tab.class} classes={{ root: classes.tabRoot, selected: classes.tabSelected, label: classes.tabLabel }} label={tab.label} />)}
        </Tabs>
        <div className="row divider-sm" />
        {this.props.form.tabs.map((tab, idx) => {
          if (idx !== this.state.currentTab) {
            return null;
          }
          return (
            <div key={idx} className={tab.wrapperClass} >
              {jsxForm}
            </div>
          );
        })}
      </form>
    );
  }
}

export default withStyles(styles)(TabbedForm);
