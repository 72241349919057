import React from 'react';
import loadable from 'react-loadable';
import Loader from 'components/UI/Loader/Loader';

const LoadingComponent = (props) => {
  if (props.error) {
    return (
      <div>
        Error!
        <button onClick={props.retry}>Retry</button>
      </div>
    );
  }
  return <Loader />;
};

const loadableApps = loadable({
  loader: () => import('./Apps/Apps'),
  loading: LoadingComponent
});

const loadableBackOrder = loadable({
  loader: () => import('./BackOrder/BackOrder'),
  loading: LoadingComponent
});

const loadableImport = loadable({
  loader: () => import('./Import/Config'),
  loading: LoadingComponent
});

const loadableImportDetail = loadable({
  loader: () => import('./Import/Detail'),
  loading: LoadingComponent
});

const loadableConfig = loadable({
  loader: () => import('./Config/Config'),
  loading: LoadingComponent
});

export default {
  Apps: {
    component: loadableApps,
    route: '/Apps/:info'
  },
  BackOrder: {
    component: loadableBackOrder,
    route: '/BackOrder/:idbackorder'
  },
  Import: {
    component: loadableImport,
    route: '/Import/config'
  },
  Imports: {
    component: loadableImportDetail,
    route: '/Imports/:idimport'
  },
  Config: {
    component: loadableConfig,
    route: '/Config'
  },
};
