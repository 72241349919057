import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import Checkbox from './Inputs/Checkbox';
import Switch from './Inputs/Switch';
import OnOff from './Inputs/OnOff';
import Radio from './Inputs/Radio';
import AutoComplete from './Inputs/AutoComplete';
import AsyncAutoComplete from './Inputs/Async';
import Input from './Inputs/Input';
import DatePicker from './Inputs/DatePicker';
import HiddenText from './Inputs/HiddenText';
import Dropzone from './Inputs/Dropzone';
import ColorPicker from './Inputs/Color';

const HandleChange = (event, props, value) => {
  const newEvent = { ...event, target: { ...event.target } };
  const values = props.value ? [...props.value] : [];
  switch (props.editor ? props.editor.toLowerCase() : props.editor) {
    case 'checkbox': // onChange
    case 'switch': // onChange
      if (event.target.checked && values.indexOf(value) === -1) {
        values.push(value);
      } else if (values.indexOf(value) !== -1) {
        values.splice(values.indexOf(value), 1);
      }
      newEvent.target.value = values;
      return props.onChange(newEvent);
    case 'select': // onDelete
      if (props.multiple) {
        values.splice(values.indexOf(value), 1);
        newEvent.target.value = values;
        return props.onChange(newEvent);
      }
      return props.onChange(event);
    case 'onoff': // onChange
      newEvent.target.value = value;
      return props.onChange(newEvent);
    default:
      return props.onChange(event);
  }

};

const renderCheckbox = (props) => {
  const {
    onChange, ...rest
  } = props;
  return (<Checkbox onChange={(event, value) => HandleChange(event, props, value)} {...rest} />);
};

const renderSwitch = (props) => {
  const {
    onChange, ...rest
  } = props;
  return (<Switch onChange={(event, value) => HandleChange(event, props, value)} {...rest} />);
};

const renderOnOff = (props) => {
  const {
    onChange, ...rest
  } = props;
  return (<OnOff onChange={(event, value) => HandleChange(event, props, value)} {...rest} />);
};

class Field extends Component {

  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = { inputProps: {} };
  }

  componentDidMount() {
    if (this.input.addEventListener) {
      /*
        Hack form chrome autofill bug
      */
      this.input.addEventListener('animationstart', (e) => {
        switch (e.animationName) {
          case 'onAutoFillStart':
            return this.setState({inputProps: {shrink: true}});
          case 'onAutoFillCancel':
            return this.setState({inputProps: {}});
          default:
            return null;
        }
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.options_params || (this.props.display_conditions && this.props.display_conditions.length > 0)) {
      return true;
    }

    return nextProps.value !== this.props.value || nextProps.error !== this.props.error || nextState !== this.state;
  }

  render() {
    const { classes, ...rest } = this.props;
    if (classes.label === '') {
      classes.label = Field.defaultProps.classes.label;
    }
    if (classes.input === '') {
      classes.input = Field.defaultProps.classes.input;
    }
    if (classes.wrapper === '') {
      classes.wrapper = Field.defaultProps.classes.wrapper;
    }

    let component = null;

    if (this.props.display_conditions && this.props.display_conditions.length > 0) {
      const display = this.props.display_conditions.reduce((curr, cond) => {
        const targetValue = this.props.form.getFieldValue(cond.target);
        if ((!targetValue && cond.value) || !curr) {
          return curr;
        }
        switch (cond.type) {
          case 'in':
            return cond.value.indexOf(targetValue) !== -1;
          case 'notin':
            return cond.value.indexOf(targetValue) === -1;
          case '!=':
            return cond.value !== targetValue;
          case '=':
            return cond.value === targetValue;
          case '<':
            return cond.value < targetValue;
          case '>':
            return cond.value > targetValue;
          case '<=':
            return cond.value <= targetValue;
          case '>=':
            return cond.value >= targetValue;
          default:
            return curr;
        }
      }, true);
      if (!display) {
        return null;
      }
    }

    switch (this.props.editor ? this.props.editor.toLowerCase() : this.props.editor) {
      case 'checkbox':
        component = renderCheckbox(this.props);
        break;
      case 'switch':
        component = renderSwitch(this.props);
        break;
      case 'onoff':
        component = renderOnOff(this.props);
        break;
      case 'dropzone':
        component = (<Dropzone {...this.props} />);
        break;
      case 'colorpicker':
        component = (<ColorPicker {...this.props} />);
        break;
      /*
      case 'select':
        component = (<Select {...this.props} onDelete={(e, value) => HandleChange(e, this.props, value)} />);
        break;
      */
      case 'datepickerv2':
      case 'datepicker':
      case 'date':
        component = (
          <DatePicker
            inputRef={(ref) => { this.input = ref; }}
            input={this.input}
            InputLabelProps={{...this.state.inputProps, shrink: true}}
            disabled={this.state.disabled}
            {...this.props}
            editor="date"
          />
        );
        break;
      case 'datetime':
        component = (
          <DatePicker
            inputRef={(ref) => { this.input = ref; }}
            input={this.input}
            InputLabelProps={{...this.state.inputProps, shrink: true}}
            disabled={this.state.disabled}
            {...this.props}
            editor="datetime"
          />
        );
        break;
      case 'select':
        component = (<AutoComplete {...rest} cssClasses={classes} />);
        break;
      case 'asyncselect':
        component = (<AsyncAutoComplete {...rest} cssClasses={classes} />);
        break;
      case 'radio':
        component = (<Radio {...this.props} />);
        break;
      case 'submit':
        component = (<Button type="submit" {...this.props} />);
        break;
      case 'hidden':
        return null;
      case 'hiddentext':
        component = <HiddenText inputRef={(ref) => { this.input = ref; }} input={this.input} InputLabelProps={{...this.state.inputProps}} disabled={this.state.disabled} {...this.props} />;
        break;
      default:
        component = <Input inputRef={(ref) => { this.input = ref; }} input={this.input} InputLabelProps={{...this.state.inputProps}} disabled={this.state.disabled} {...this.props} />;
        break;
    }
    if (this.props.classes.wrapper && this.props.classes.wrapper !== '') {
      return (
        <div className={this.props.classes.wrapper}>
          {component}
        </div>
      );
    }
    return component;
  }
}
Field.defaultProps = {
  classes: { label: '', input: '', wrapper: 'col-12' }
};

export default Field;
