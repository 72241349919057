import React from 'react';
import Header from 'components/Header';
import Sidenav from 'components/Sidenav';
import Footer from 'components/Footer';
import TopBar from 'components/TopBar/TopBar';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Dashboard from './Dashboard/Dashboard';
import AbstractContainer from './AbstractContainer/AbstractContainer';

const mainApp = props => (
  <div className="main-app-container">
    <Sidenav />
    <section id="page-container" className="app-page-container">
      <Header />
      <div className="app-content-wrapper">
        <div className="app-content">
          <div className="full-height">
            <TopBar />
            <Switch>
              <Route path="/" exact component={Dashboard} />
              <Route path="/:component" component={AbstractContainer} />
              <Route component={() => <Redirect to="/404" />} />
            </Switch>
          </div>
        </div>

        <Footer />
      </div>
    </section>
  </div>
);

const mapStateToProps = (state, ownProps) => ({
  isLogged: state.auth.token !== null,
});

export default connect(mapStateToProps)(mainApp);

