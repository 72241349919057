import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Icons from 'components/UI/Icons';


const styles = {
  root: {
  },
  label: {
    textTransform: 'none',
    fontWeight: 400
  },
};

const sidenavItem = (props) => {
  let icon = null;
  if (props.item.icon) {
    if (props.item.icon.class || props.item.icon.caption) {
      icon = <i className={props.item.icon.class}>{props.item.icon.caption}</i>;
    } else {
      icon = <Icons className={props.level <= 0 ? 'nav-icon' : ''} type={props.level <= 0 ? 'svg' : 'icon'} icon={props.item.icon} />;
    }
  } else if (props.level === 1) {
    icon = <i className="material-icons">keyboard_arrow_right</i>;
  } else if (props.level <= 0) {
    icon = <i className="nav-icon material-icons">keyboard_arrow_right</i>;
  }
  let button = (
    <ButtonBase
      classes={{
        root: props.classes.root, // class name, e.g. `classes-nesting-root-x`
      }}
      onClick={props.clicked}
      href="#"
    >
      <div className={props.classes.label}>
        {icon}
        <span className={classnames(props.item.textClass, 'nav-text')}>
          {props.item.name}
        </span>
      </div>
    </ButtonBase>
  );

  if (props.item.target_value && props.item.target_value !== '' && props.item.target_type === 'url') {
    button = (
      <ButtonBase
        classes={{
          root: props.classes.root, // class name, e.g. `classes-nesting-root-x`
        }}
        onClick={props.clicked}
        component={props => <NavLink {...props} />}
        to={props.item.target_value}
      >
        <div className={props.classes.label}>
          {icon}
          <span className={classnames(props.item.textClass, 'nav-text')}>
            {props.item.name}
          </span>
        </div>
      </ButtonBase>
    );
  }
  return (
    <li className={classnames({
      open: props.opened,
      active: props.opened || props.active,
    })}>
      {button}
      {props.children}
    </li>
  );
};

export default withStyles(styles)(sidenavItem);
