import store from 'store';
import * as actionTypes from './ActionTypes';

export function authStart() {
  return { type: actionTypes.AUTH_START };
}

export const authSuccess = (token, userInfos) => {
  store.set('auth-token', token);
  return {
    type: actionTypes.AUTH_SUCCESS,
    payload: {
      authToken: token,
      userInfos
    }
  };
};

export const authFail = error => ({
  type: actionTypes.AUTH_FAIL,
  payload: {
    error
  }
});

export const authLogout = () => {
  store.remove('auth-token');
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export function authCheckStart() {
  return { type: actionTypes.AUTH_CHECK_START };
}

export function authCheckSuccess(token, nav, apps) {
  store.set('auth-token', token);
  return {
    type: actionTypes.AUTH_CHECK_SUCCESS,
    payload: {
      authToken: token,
      ...nav,
      apps
    }
  };
}

export const authCheckFail = error => ({
  type: actionTypes.AUTH_CHECK_FAIL,
  payload: {
    error
  }
});

const parseNavigation = (item, curr) => {
  if (item.children) {
    item.children.reduce((prev, child) => parseNavigation(child, prev), curr);
  }

  if (item.target_value.indexOf('/Apps/') !== -1) {
    curr.push(item.target_value.split('/Apps/')[1]);
  }
  return curr;
};

export const authCheck = axios => (dispatch) => {
  dispatch(authCheckStart());
  axios.get('Navigation/listing')
    .then((response) => {
      let apps = [];
      if (response.data.response.navigation) {
        apps = response.data.response.navigation.reduce((prev, item) => parseNavigation(item, prev), []);
      }
      dispatch(authCheckSuccess(response.data.session_id, response.data.response, apps));
    })
    .catch((err) => {
      dispatch(authCheckFail(err.response.data));
    });
};

export const auth = (formData, axios) => (dispatch) => {
  dispatch(authStart());
  axios.post('Auth/login', formData)
    .then((response) => {
      dispatch(authSuccess(response.data.session_id, response.data.response));
      dispatch(authCheck(axios));
    })
    .catch((err) => {
      dispatch(authFail(err.response.data));
    });
};

export const logout = axios => (dispatch) => {
  if (axios) {
    axios.get('Auth/logout')
      .then((response) => {
        dispatch(authLogout());
      })
      .catch((err) => {
        dispatch(authLogout());
      });
  } else {
    dispatch(authLogout());
  }
};

export const setActivPath = path => ({
  type: actionTypes.AUTH_ACTIV_PATH,
  payload: {
    path
  }
});
