import React, { Component } from 'react';
import * as Pickers from 'material-ui-pickers';
import moment from 'moment';

class DatePicker extends Component {

  handleDateChange = (date) => {
    this.props.onChange({ target: { value: moment(date).format('YYYY-MM-DD HH:mm:ss') }});
  }

  formatDateSelectLabel = (date, invalidLabel) => {
    if (date === null) {
      return '';
    }

    return date ? moment(date).format('LL') : invalidLabel;
  };

  render() {
    const {
      label, validators, placeholder, classes, reference, editor, InputLabelProps,
      input, raw_attributes: rawAttributes, value, ...rest
    } = this.props;

    let val = value;

    this.input = input;
    const InputProps = { className: classes.input };
    let DateComponent = Pickers.DatePicker;
    switch (editor) {
      case 'datetime':
        DateComponent = Pickers.DateTimePicker;
        break;
      case 'time':
        DateComponent = Pickers.TimePicker;
        break;
      default:
        DateComponent = Pickers.DatePicker;
    }
    val = val ? new Date(val) : null;

    return (
      <DateComponent
        label={label}
        placeholder={placeholder}
        keyboard
        clearable
        InputLabelProps={{ className: classes.label, ...InputLabelProps }}
        InputProps={InputProps}
        fullWidth
        value={val}
        labelFunc={this.formatDateSelectLabel}
        {...rest}
        {...rawAttributes}
        onChange={this.handleDateChange}
      />
    );
  }
}

export default DatePicker;
