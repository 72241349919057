import PropTypes from 'prop-types';
import React from 'react';
import { withRouter, matchPath } from 'react-router';
import { connect } from 'react-redux';

import scrollDecorator from 'hoc/ScrollBar';
import SidenavSubMenu from './SidenavSubMenu/SidenavSubMenu';
import SidenavItem from './SidenavItem/SidenavItem';
import * as actions from '../../../store/actions';

const scrollBarOptions = {
  color: '#000'
};

class SidenavMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};

    const active = this.props.menu.reduce((prev, curr, key) => {
      if (key === undefined) {
        return prev;
      }
      return this.getActivePath(this.props.menu, prev, curr, key.toString(), this.props.location);
    }, []);

    if (active.length > 0) {
      props.setActivPath(active[0]);
    }
  }

  componentDidMount() {
    const { history } = this.props;

    history.listen((location) => {
      const active = this.props.menu.reduce((prev, curr, key) => {
        if (key === undefined) {
          return prev;
        }
        return this.getActivePath(this.props.menu, prev, curr, key.toString(), location);
      }, []);
      if (active.length > 0 && this.props.activPath !== active[0]) {
        this.props.setActivPath(active[0]);
      }
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.activPath !== this.props.activPath;
  }

  onNodeClick(event, item, keyPath, key) {
    if (item.target_type !== 'url') {
      event.preventDefault();
      if (this.props.activPath.indexOf(keyPath) === 0) {
        this.props.setActivPath(keyPath.substring(0, keyPath.lastIndexOf(key)));
      } else {
        this.props.setActivPath(keyPath);
      }
    }
  }

  getActivePath = (menu, prevs, curr, keyPath, location) => {
    if (!curr.children || curr.children.length === 0) {

      const match = matchPath(location.pathname, { path: curr.target_value, exact: curr.target_value === '/' });

      if (match) {
        prevs.push(keyPath);
        return prevs;
      }

      return prevs;
    }

    const childrenList = curr.children.length ? curr.children.reduce((p, c, k) => {
      if (c === undefined || k === undefined) {
        return p;
      }
      return this.getActivePath(menu, p, c, `${keyPath}.children.${k}`, location);
    }, prevs) : prevs;

    return childrenList;
  }

  /* eslint-disable no-param-reassign */
  setDisplayMenu(menu, prevs, curr, keyPath, keyid) {
    curr.keyPath = keyPath;
    const currLevel = Math.floor(keyPath.length / 2);
    const xLevel = Math.floor(keyPath.split('.').length / 2);

    if (curr.type && curr.type === 'divider') {
      const itemKey = `menu-divider-${btoa(keyPath)}`;
      prevs.push(<li key={itemKey} className={curr.class} />);
      return prevs;
    }

    if (curr.type && curr.type === 'header') {
      const itemKey = `menu-header-${btoa(keyPath + curr.name)}`;
      prevs.push(<li key={itemKey} className={curr.class}><span>{curr.name}</span></li>);
      return prevs;
    }

    /* the leaves */
    if (!curr.children || curr.children.length === 0) {
      const itemKey = `menu-leaf-${btoa(keyPath + curr.name)}`;
      prevs.push((
        <SidenavItem
          key={itemKey}
          clicked={e => this.onNodeClick(e, curr, keyPath, keyid)}
          item={curr}
          level={xLevel}
          active={this.props.activPath === keyPath}
        />
      ));
      return prevs;
    }
    /* the node */

    const key = `menu-node-${currLevel}-${btoa(keyPath + curr.name)}`;

    const openedNode = [];
    const opened = this.props.activPath.indexOf(keyPath) === 0;

    const childrenList = curr.children.length ? curr.children.reduce((p, c, k) => {
      if (c === undefined || k === undefined) {
        return p;
      }
      return this.setDisplayMenu(menu, p, c, `${keyPath}.children.${k}`, `.children.${k}`);
    }, []) : [];


    openedNode.push((
      <SidenavSubMenu
        key={key}
        opened={opened}
        clicked={e => this.onNodeClick(e, curr, keyPath, keyid)}
        item={curr}
        level={xLevel}
      >
        {childrenList}
      </SidenavSubMenu>
    ));
    prevs.push(openedNode);
    return prevs;


  }

  render() {
    /* recursive go through the menu */
    const displayMenu = this.props.menu.reduce((prev, curr, key) => {
      if (key === undefined) {
        return prev;
      }
      return this.setDisplayMenu(this.props.menu, prev, curr, key.toString());
    }, []);

    return (
      <ul className="nav">
        {displayMenu}
      </ul>
    );
  }
}

SidenavMenu.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object)
};

SidenavMenu.defaultProps = {
  menu: []
};

const mapStateToProps = state => ({
  activPath: state.auth.activPath
});

const mapDispatchToProps = dispatch => ({
  setActivPath: path => dispatch(actions.setActivPath(path))
});

export default scrollDecorator(scrollBarOptions)(connect(mapStateToProps, mapDispatchToProps)(withRouter(SidenavMenu)));
