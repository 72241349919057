import * as actionTypes from '../actions/ActionTypes';

const initialState = {
  error: null,
};

const setAxiosError = (state, payload) => ({...state, error: payload.error});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AXIOS_ERROR: return setAxiosError(state, action.payload);
    default:
      return state;
  }
};

export default reducer;
