import React from 'react';

const boxHeader = (props) => {

  const {
    className, bgColor, centered, children, icon, ...rest
  } = props;
  const classes = ['box-header', 'col-12'];
  if (bgColor) {
    classes.push(bgColor);
  }
  if (centered) {
    classes.push('text-center');
  }
  if (icon) {
    classes.push('with-icon');
  }
  if (className) {
    classes.push(className);
  }
  return (
    <div className={classes.join(' ')} {...rest}>
      {children}
    </div>
  );
};

export default boxHeader;
