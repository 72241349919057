import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Tabs, Tab } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});

class NavLeftList extends React.Component {

  static getDerivedStateFromProps(props, state) {
    let parrentElement = _.get(props.navigation, props.activPath);
    if (!parrentElement || !parrentElement.children || parrentElement.children.length < 1) {
      const parrentPath = props.activPath.split('.').reduce((p, c, i) => {
        if (props.activPath.split('.').length > 2 && i >= props.activPath.split('.').length - 2) {
          return p;
        }
        if (p === '') {
          return c;
        }
        return `${p}.${c}`;
      }, '');
      parrentElement = _.get(props.navigation, parrentPath);
    }

    if (!parrentElement || !parrentElement.children) {
      return {};
    }

    return { parrentElement };
  }

  state = {parrentElement: null}

  onTabChange = (value) => {
    this.state.parrentElement.children.reduce((p, c) => {
      if (c.target_type !== 'url') {
        return p;
      }
      if (p === value) {
        this.props.history.push(c.target_value);
      }
      const n = p + 1;
      return n;
    }, 0);
  }

  render() {
    if (!this.props.activPath || !this.state.parrentElement) {
      return null;
    }
    let value = -1;
    const tabs = this.state.parrentElement.children.reduce((p, c) => {
      if (c.target_type !== 'url') {
        return p;
      }
      if (c.keyPath === this.props.activPath) {
        value = p.length;
      }
      p.push((
        <Tab key={c.keyPath} label={c.name} />
      ));
      return p;
    }, []);

    if (tabs.length === 0) {
      return null;
    }

    return (

      <div className={this.props.classes.root}>
        <Tabs
          value={value < 0 ? false : value}
          onChange={(event, value) => this.onTabChange(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  activPath: state.auth.activPath,
  navigation: state.auth.navigation
});

export default withStyles(styles)(connect(mapStateToProps)(withRouter(NavLeftList)));

