import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'; // v1.x
import 'moment/locale/fr';
import reducers from './store/reducers';
import App from './containers/App/App';
import lightTheme from './containers/App/themes/lightTheme';

/* eslint-disable no-underscore-dangle */

const composeEnhancers = process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) : compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
// const store = createStore(reducers, applyMiddleware(thunk));

ReactDOM.render(
  (
    <Provider store={store}>
      <BrowserRouter>
        <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Switch>
              <Route path="/" component={App} />
            </Switch>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </BrowserRouter>
    </Provider>
  ), document.getElementById('app-container')
);
