import React from 'react';

const BoxBody = (props) => {
  const classes = ['box-body', 'col-12'];
  if (props.bgColor) {
    classes.push(props.bgColor);
  } else if (props.dark) {
    classes.push('box-dark');
  }
  if (props.centered) {
    classes.push('text-center');
  }
  if (props.flex) {
    classes.push('d-flex');
    classes.push('flex-column');
  }
  return (
    <div className={classes.join(' ')} >
      {props.children}
    </div>
  );
};

export default BoxBody;
