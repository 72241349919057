import axios from 'axios';
import APPCONFIG from 'constants/Config';
import store from 'store';
import { Html5Entities } from 'html-entities';

let lastRequestToken = null;


/* eslint-disable no-use-before-define */
const decodeHTMLEntities = (value) => {
  if (value !== null && value !== '') {
    switch (typeof value) {
      case 'object':
        if (Array.isArray(value)) {
          return arrayDecodeHTMLEntities(value);
        }
        return objectDecodeHTMLEntities(value);
      case 'array':
        return arrayDecodeHTMLEntities(value);
      case 'string':
        return Html5Entities.decode(value);
      default:
        return value;
    }
  }
  return value;
};
/* eslint-enable no-use-before-define */

const arrayDecodeHTMLEntities = (values) => {
  const decodedValues = values.reduce((prev, value) => {
    prev.push(decodeHTMLEntities(value));
    return prev;
  }, []);
  return decodedValues;
};

const objectDecodeHTMLEntities = (values) => {
  const decodedValues = { ...values };
  Object.keys(values).map((key) => {
    decodedValues[key] = decodeHTMLEntities(values[key]);
    return null;
  });
  return decodedValues;
};

/* eslint-disable arrow-body-style */

const instance = axios.create({
  baseURL: `${document.location.protocol}//${APPCONFIG.API_URL}`
});

instance.isCancel = axios.isCancel;
instance.getCancelToken = () => axios.CancelToken.source();

/*
  Global interceptors
  to parse errors globaly
*/

const getErrorMessage = (response) => {
  switch (response.data.message) {
    case 'Unknown Error':
    case undefined:
    case null:
      return 'Une erreur inconnue s\'est produite';
    default:
      return response.data.message;
  }
};

const getErrorCode = (status) => {
  switch (status) {
    case 403:
      return 'KO-AUTH';
    default:
      return null;
  }
};


instance.interceptors.request.use((config) => {
  const token = store.get('auth-token');
  const newConfig = { ...config, headers: { ...config.headers } };
  // console.log('token : ', token);
  if (token !== null && token !== undefined) {
    newConfig.headers['X-Auth-Token'] = token;
  }
  // console.log(lastRequestToken);
  if (lastRequestToken) {
    lastRequestToken.cancel();
    lastRequestToken = null;
  }
  // requette annulable
  if (config.cancelToken && config.cancelToken.token) {
    lastRequestToken = config.cancelToken;
    newConfig.cancelToken = config.cancelToken.token;
  }
  return newConfig;
}, (error) => {
  // console.log('request error : ', error);
  return Promise.reject(error);
});

instance.interceptors.response.use((response) => {
  // console.log(response);
  // console.log('response.data', response.data);
  // console.log('response.data.state', response.data.state);
  // console.log('response.data.state === \'OK\'', response.data.state === 'OK');
  // console.log('response.data.response', response.data.response);
  if (response.data && response.data.state && response.data.state === 'OK' && response.data.response) {
    const decodedResponse = { ...response, data: { ...response.data, response: { ...response.data.response } } };
    decodedResponse.data.response = decodeHTMLEntities(response.data.response);
    // console.log(decodedResponse);
    return decodedResponse;
  }
  if (response.data && response.data.state) {
    const err = { response: { ...response, data: { ...response.data, message: getErrorMessage(response) } } };
    return Promise.reject(err);
  }

  const err = { response: { data: { code: 'KO', state: 'KO', message: getErrorMessage(response) } } };
  // console.log('err', err);
  return Promise.reject(err);
}, (error) => {
  // console.log('error', error);
  if (axios.isCancel(error)) {
    lastRequestToken = null;
    return Promise.reject(error);
  }
  // console.log('error', error.message);
  Object.keys(error).map((key) => {
    // console.log(`error ${key}`, error[key]);
    return null;
  });
  const err = { response: { data: { code: 'KO-SERVER', state: 'KO' } } };
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
    const e = {
      ...error,
      response: {
        ...error.response,
        data: {
          ...error.response.data,
          message: getErrorMessage(error.response),
          code: getErrorCode(error.response.status),
          status: error.response.status
        }
      }
    };
    return Promise.reject(e);
  } else if (error.request) {
    // The request was made but no response was received
    switch (error.message) {
      case 'Network Error':
        err.response.data.code = 'KO-NETWORK';
        break;
      default:
        break;
    }
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log('Error', error.message);
  }
  return Promise.reject(err);
});


export default instance;
