import cyan from '@material-ui/core/colors/cyan';
import grey from '@material-ui/core/colors/grey';
import common from '@material-ui/core/colors/common';
import {fade} from '@material-ui/core/styles/colorManipulator';

const cyan500 = cyan['500'];
const cyan600 = cyan['600'];
const grey300 = grey['300'];
const grey400 = grey['400'];
const fullBlack = common.black;

const darkBlack = fade(fullBlack, 0.87);
/**
 *  Light Theme is the default theme used in material-ui. It is guaranteed to
 *  have all theme variables needed for every component. Variables not defined
 *  in a custom theme will default to these values.
 */

export default {
  direction: 'ltr',
  palette: {
    common: {
      black: '#000',
      white: '#fff',
      lightblue: cyan['100']
    },
    type: 'light',
    primary: {
      light: grey400,
      main: cyan500,
      dark: cyan600,
      contrastText: '#fff',
    },
    numead: {
      turquoise: '#19c3dd',
      pink: '#e583c2',
      lightPink: '#e583c2',
      yellow: '#f7dc69',
      green: '#63d9ca',
      blue: '#1196aa',
      grey: '#888888',
      lightGrey: '#bababa',
    },
    /*
    secondary: {
      light: grey500,
      main: green400,
      dark: green500,
      contrastText: '#fff',
    },
    */
    secondary: {

      main: 'rgb(225, 0, 80)',
      light: 'rgb(231, 51, 115)',
      dark: 'rgb(157, 0, 56)',
      contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#fff', // default button
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#eeeeee', // defaut button hover
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: darkBlack,
      secondary: fade(darkBlack, 0.54),
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: '#fff',
      default: '#fff'
    },
    action: {
      active: darkBlack,
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: fade(darkBlack, 0.3),
      disabledBackground: 'rgba(0, 0, 0, 0.12)'
    }
  },
  typography: {
    fontSize: 14,
    htmlFontSize: 14,
    fontFamily: 'Roboto, sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    useNextVariants: true,
  },
  overrides: {
    MuiChip: {
      root: {
        backgroundColor: grey300
      },
    },
    MuiButtonBase: {
      root: {
        outline: 'none !important',
        lineHeight: '1.2rem'
      },
    },
    MuiButton: {
      root: {
        outline: 'none !important',
        lineHeight: '1.2rem'
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          '&$selected': {
            backgroundColor: grey['200'],
          },
          '&$hover:hover': {
            backgroundColor: grey300,
          },
        },
        '&$selected': {
          backgroundColor: grey['200'],
        },
        '&$hover:hover': {
          backgroundColor: grey300,
        },
      },
    },
    MUIDataTableHeadCell: {
      toolButton: {
        whiteSpace: 'nowrap'
      }
    },
    MuiTableCell: {
      root: {
        padding: '4px 24px 4px 24px'
      }
    },
    MuiInputAdornment: {
      root: {
        maxHeight: '2rem'
      }
    },
    MuiPaper: {
      elevation4: {
        boxShadow: 'none'
      }
    }
  },
};
