import React, { PureComponent } from 'react';
import Collapse from '@material-ui/core/Collapse';
import SidenavItem from '../SidenavItem/SidenavItem';

class sidenavSubMenu extends PureComponent {

  render() {
    return (
      <SidenavItem {...this.props}>
        <Collapse unmountOnExit mountOnEnter in={this.props.opened}>
          <ul>
            {this.props.children}
          </ul>
        </Collapse>
        <i className="material-icons icon-has-ul">arrow_drop_down</i>
      </SidenavItem>
    );
  }
}

export default sidenavSubMenu;
