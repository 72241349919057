import React, { Component } from 'react';
import { Collapse, Button, FormControl, FormLabel } from '@material-ui/core';
import classname from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

class Panel extends Component {

  state = { collapsed: true }

  toogleCollapse = () => {
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));
  }

  render() {
    const {
      classes, children, label, ...rest
    } = this.props;

    if (this.props.type === 'collapse') {
      const icon = this.state.collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />;
      const buttonLabel = this.state.collapsed ? this.props.labels.open : this.props.labels.close;
      return (
        <FormControl fullWidth className={classname('panel', classes.div)} component="div" {...rest}>
          {label && label.length > 0 ? <FormLabel className={classes.label} component="legend">{label}</FormLabel> : null}
          <Button variant="contained" onClick={this.toogleCollapse}>{buttonLabel}{icon}</Button>
          <Collapse in={!this.state.collapsed}>
            {children}
          </Collapse>
        </FormControl>
      );
    }
    return (
      <FormControl fullWidth className={classname('panel', classes.div)} component="div" {...rest}>
        {label && label.length > 0 ? <FormLabel className={classes.label} component="legend">{label}</FormLabel> : null}
        {children}
      </FormControl>
    );
  }
}

Panel.defaultProps = {
  classes: { label: '', div: '', wrapper: ''}
};

export default Panel;
