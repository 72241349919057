import React, { Component } from 'react';
import classnames from 'classnames';
import { Button, FormLabel } from '@material-ui/core';
import TabbedForm from './TabbedForm';
import SimpleForm from './SimpleForm';
import Panel from './Panel';
import Fieldset from './Fieldset';
import Field from './Field/Field';

class Form extends Component {

  static getDerivedStateFromProps(props, state) {
    if (!props.error || props.error === state.error) {
      return state;
    }

    if (!props.formURL && props.form && !state.fields) {
      return { ...state, ...this.props.form, loading: false };
    }

    const newFields = { ...state.fields };
    const newState = { ...state, error: props.error, fields: newFields };
    switch (props.error.code) {
      case 'KO-VALIDATION':
        Object.keys(props.error.response).map((fieldKey) => {
          if (newFields[fieldKey]) {
            newFields[fieldKey] = { ...state.fields[fieldKey] };
            newFields[fieldKey].error = true;
            newFields[fieldKey].helperText = props.error.response[fieldKey];
          }
          return null;
        });
        return newState;
      case 'KO-WRONG-PASSWORD':
        if (newFields.password) {
          newFields.password.error = true;
          newFields.password.helperText = props.error.message;
        }
        return newState;
      case 'KO-WRONG-LOGIN':
        if (newFields.login) {
          newFields.login.error = true;
          newFields.login.helperText = props.error.message;
        }
        return newState;
      case 'KO':
        return state;

      default:
        return state;
    }
  }

  constructor(props) {
    super(props);
    if (!props.formURL && props.form) {
      this.state = { ...this.props.form, loading: false };
    } else {
      this.state = { loading: true };
    }
    this.form = null;
  }

  onFieldChange = (inputKey, value, callback) => {
    const { error, helperText, ...updatedField } = this.state.fields[inputKey];
    updatedField.value = value;
    // const updatedFields = { ...this.state.fields, [inputKey]: updatedField };
    let updatedFields = {...this.state.fields};
    updatedFields[inputKey] = updatedField;
    this.setState({ fields: updatedFields }, callback && typeof callback === 'function' ? callback : undefined);
  }

  getFieldValue = (inputKey) => {
    if (this.state.fields[inputKey]) {
      return this.state.fields[inputKey].value;
    }
    return null;
  }

  getName = () => this.state.name;

  getId = () => this.state.id;

  addAllowedValue = (allowedValue, inputKey) => {
    if (this.state.fields[inputKey] && this.state.fields[inputKey].allowed_values
      && this.state.fields[inputKey].raw_attributes && this.state.fields[inputKey].raw_attributes.creatable) {
      const updatedField = { ...this.state.fields[inputKey] };
      const isNew = updatedField.allowed_values.reduce((res, value) => {
        if (!res || (allowedValue.value === value.value && allowedValue.label === value.label)) {
          return false;
        }
        return res;
      }, true);
      if (isNew) {
        updatedField.allowed_values = [...updatedField.allowed_values, allowedValue];
        if (updatedField.name.substring(updatedField.name.length - 2) === '[]') {
          if (!updatedField.value) {
            updatedField.value = [];
          }
          updatedField.value = [...updatedField.value, allowedValue.value];
        } else {
          updatedField.value = allowedValue.value;
        }
        // console.log(updatedField.value);
        const updatedFields = { ...this.state.fields, [inputKey]: updatedField };
        this.setState({ fields: updatedFields });
      }
    }
  }

  handleFieldChange = (inputKey, event) => {
    const {target: {value}} = event;
    this.onFieldChange(
      inputKey,
      value,
      this.props.onFieldChange && typeof this.props.onFieldChange === 'function'
        ? () => this.props.onFieldChange(inputKey, value)
        : undefined
    );
  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({ ...this.props.form });
  }

  handleSubmit = () => {
    const formData = {};

    Object.keys(this.state.fields).map((key) => {
      const { value, ...field } = { ...this.state.fields[key] };
      if (field.raw_attributes && field.raw_attributes.creatable && !field.raw_attributes.creatable.action) {
        // Si le champ est 'creatable'
        // Si les allowed_values ne contiennent pas la valeur
        const contains = field.allowed_values.reduce((prev, option) => (
          prev || option.value === value
        ), false);
        if (!contains && key.indexOf('id') === 0) {
          formData[key.substring(2)] = value;
          return null;
        }
      }
      if (value !== undefined && value !== null) {
        formData[key] = value;
      }
      return null;
    });

    this.props.handleSubmit(formData, this.state.fields);
  }

  clickSubmit = () => {
    this.form.submit();
  }

  buildForm = (constraint = field => field !== null) => {
    let currentPanelID = -1;
    let currentFieldSetID = -1;
    let lastFieldSetID = -1;
    let lastPanelID = -1;
    const buffer = [];

    if (this.state.infos_content) {
      currentPanelID = 0;
      currentFieldSetID = 0;
      buffer[currentPanelID] = {
        id: lastPanelID,
        fieldsets: [
          {
            id: lastFieldSetID,
            childrens: [
              <FormLabel key="infos_content" >{this.state.infos_content}</FormLabel>
            ]
          }
        ]
      };
      currentPanelID = 1;
      currentFieldSetID = 1;
    }

    Object.keys(this.state.fields).forEach((fieldKey, idx) => {
      const { ...field } = this.state.fields[fieldKey];
      if (!constraint(field)) {
        return;
      }

      if (field.panel !== lastPanelID) {
        const newPanelID = field.panel > 0 && this.state.panels && this.state.panels[field.panel] ? field.panel : 0;
        if (newPanelID !== lastPanelID) {
          lastPanelID = newPanelID;
          currentPanelID = buffer.length;
          buffer[currentPanelID] = { id: lastPanelID, fieldsets: [] };
          lastFieldSetID = -1;
        }
      }

      if (field.fieldset !== lastFieldSetID) {
        const newFieldSetID = field.fieldset > 0 && this.state.fieldsets && this.state.fieldsets[field.fieldset] ? field.fieldset : 0;
        if (newFieldSetID !== lastFieldSetID) {
          lastFieldSetID = newFieldSetID;
          currentFieldSetID = buffer[currentPanelID].fieldsets.length;
          buffer[currentPanelID].fieldsets[currentFieldSetID] = { id: lastFieldSetID, childrens: [] };
        }
      }

      const fieldJSX = (
        <Field
          onChange={event => this.handleFieldChange(fieldKey, event)}
          key={`pn${currentPanelID}fs${currentFieldSetID}fg${idx}`}
          {...field}
          margin={this.props.margin ? this.props.margin : 'dense'}
          form={this}
          multiple={field.name.substring(field.name.length - 2) === '[]'}
          name={fieldKey}
        />
      );
      buffer[currentPanelID].fieldsets[currentFieldSetID].childrens.push(fieldJSX);
    });

    if (this.state.buttons && Object.keys(this.state.buttons).length > 0) {
      buffer[currentPanelID + 1] = { id: 0, fieldsets: [] };
      buffer[currentPanelID + 1].fieldsets[0] = { id: 0, childrens: [] };

      if (this.state.buttons.reset && this.props.showButtons) {
        const { label: resetLabel, classes: resetClasses, ...resetRest } = this.state.buttons.reset;
        const resetJSX = (
          <Button
            key="reset"
            onClick={this.resetForm}
            type="reset"
            className={resetClasses}
            {...resetRest}
          >
            {resetLabel}
          </Button>
        );
        buffer[currentPanelID + 1].fieldsets[0].childrens.push(resetJSX);
      }

      if (this.state.buttons.submit) {
        const { label: submitLabel, classes: submitClasses, ...submitRest } = this.state.buttons.submit;
        const submitJSX = (
          <Button
            key="submit"
            type="submit"
            className={classnames(submitClasses, { 'd-none': !this.props.showButtons })}
            {...submitRest}
          >
            {submitLabel}
          </Button>
        );
        buffer[currentPanelID + 1].fieldsets[0].childrens.push(submitJSX);
      }
    } else {
      buffer[currentPanelID + 1] = { id: 0, fieldsets: [] };
      buffer[currentPanelID + 1].fieldsets[0] = { id: 0, childrens: [] };

      const submitJSX = (
        <Button
          key="submit"
          type="submit"
          className="d-none"
        >
          .
        </Button>
      );
      buffer[currentPanelID + 1].fieldsets[0].childrens.push(submitJSX);
    }


    const jsxForm = buffer.map((panel, pnidx) => {
      const panelContent = panel.fieldsets.map((fieldset, fsidx) => {
        if (fieldset.id >= 0 && this.state.fieldsets && this.state.fieldsets[fieldset.id]) {
          return (
            <Fieldset classes={this.props.classes} key={`pn${pnidx}fs${fsidx}`} {...this.state.fieldsets[fieldset.id]}>
              {fieldset.childrens}
            </Fieldset>
          );
        }
        return fieldset.childrens;
      });

      if (panel.id >= 0 && this.state.panels && this.state.panels[panel.id]) {
        return (
          <Panel key={`pn${pnidx}`} {...this.state.panels[panel.id]}>
            {panelContent}
          </Panel>
        );
      }
      return panelContent;
    });

    return jsxForm;
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    let FormComponent = SimpleForm;
    if (this.state.tabs && this.state.tabs.length > 0) {
      FormComponent = TabbedForm;
    }
    const { form, handleSubmit, ...rest } = this.props;
    return (<FormComponent
      formRef={(r) => { this.form = r; }}
      form={this.state.form}
      buildForm={this.buildForm}
      handleSubmit={this.handleSubmit}
      {...rest}
    />
    );
  }
}

export default Form;
