const date = new Date();
const year = date.getFullYear();

const APPCONFIG = {
  brand: 'Refurbplace',
  brandLogo: {
    big: '/assets/images/logo/refurbplace.png',
    small: '/assets/images/logo/refurbplace.png',
    small_white: '/assets/images/logo/refurbplace_white.png',
  },
  year,
  productLink: 'https://refurbplace.com/',
  API_URL: 'admin-partner-api.refurbplace.com', // (https|http):// will be added automaticaly from document.location.protocol
  AutoCloseMobileNav: true, // true, false. Automatically close sidenav on route change (Mobile only)
  color: {
    primary: '#00BCD4',
    success: '#8BC34A',
    info: '#66BB6A',
    infoAlt: '#7E57C2',
    warning: '#FFCA28',
    danger: '#F44336',
    text: '#3D4051',
    gray: '#EDF0F1'
  },
  settings: {
    layoutBoxed: false, // true, false
    navCollapsed: false, // true, false
    navBehind: false, // true, false
    fixedHeader: true, // true, false
    sidebarWidth: 'middle', // small, middle, large
    colorOption: '14', // String: 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
    theme: 'light', // light, gray, dark
  }
};

export default APPCONFIG;
