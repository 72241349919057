import React from 'react';
import Icon from '@material-ui/core/Icon';
import {
  BatteryFull, KeyboardArrowRight, People, Store,
  Home, Settings, InsertDriveFile, Cached, Build,
  Add, Delete, Create, MoreHoriz, PictureAsPdf,
  LocalShipping, Business, PlaylistAdd, FileCopy, 
  Redo, Cancel, Check, MenuBook, MonetizationOn, 
  PlayForWork, Search, CloudDownload
} from '@material-ui/icons';

const SvgIcons = (props) => {
  const { icon, ...rest } = props;
  if (!icon) {
    return null;
  }
  if (!(typeof icon === 'string')) {
    return icon;
  }
  switch (icon) {
    case 'nav_item':
      return <KeyboardArrowRight {...rest} />;
    case 'people':
      return <People {...rest} />;
    case 'store':
      return <Store {...rest} />;
    case 'home':
      return <Home {...rest} />;
    case 'settings':
      return <Settings {...rest} />;
    case 'file':
      return <InsertDriveFile {...rest} />;
    case 'battery_full':
      return <BatteryFull {...rest} />;
    case 'cached':
      return <Cached {...rest} />;
    case 'build':
      return <Build {...rest} />;
    case 'business':
      return <Business {...rest} />;
    case 'edit':
      return <Create {...rest} />;
    case 'add':
      return <Add {...rest} />;
    case 'add_multiple':
      return <PlaylistAdd {...rest} />;
    case 'delete':
      return <Delete {...rest} />;
    case 'detail':
    case 'details':
      return <MoreHoriz {...rest} />;
    case 'pdf':
      return <PictureAsPdf {...rest} />;
    case 'local_shipping':
      return <LocalShipping {...rest} />;
    case 'copy':
      return <FileCopy {...rest} />;
    case 'redo':
      return <Redo {...rest} />;
    case 'cancel':
      return <Cancel {...rest} />;
    case 'check':
      return <Check {...rest} />;
    case 'book':
      return <MenuBook {...rest} />;
    case 'money':
      return <MonetizationOn {...rest} />;
    case 'import':
      return <PlayForWork {...rest} />;
    case 'search':
      return <Search {...rest} />;
    case 'download':
      return <CloudDownload {...rest} />;
    default:
      console.log('Unknow icon', icon);
      return null;
  }
};

const IconIcons = (props) => {
  const { icon, ...rest } = props;
  if (!icon) {
    return null;
  }
  if (!(typeof icon === 'string')) {
    return icon;
  }
  switch (icon) {
    case 'nav_item':
      return <Icon {...rest}>keyboard_arrow_right</Icon>;
    case 'people':
      return <Icon {...rest}>people</Icon>;
    case 'store':
      return <Icon {...rest}>store</Icon>;
    case 'home':
      return <Icon {...rest}>home</Icon>;
    case 'settings':
      return <Icon {...rest}>settings</Icon>;
    case 'file':
      return <Icon {...rest}>insert_drive_file</Icon>;
    case 'battery_full':
      return <Icon {...rest}>battery_full</Icon>;
    case 'cached':
      return <Icon {...rest}>cached</Icon>;
    case 'build':
      return <Icon {...rest}>build</Icon>;
    case 'edit':
      return <Icon {...rest}>create</Icon>;
    case 'add':
      return <Icon {...rest}>add</Icon>;
    case 'add_multiple':
      return <Icon {...rest}>playlist_add</Icon>;
    case 'delete':
      return <Icon {...rest}>delete</Icon>;
    case 'detail':
    case 'details':
      return <Icon {...rest}>more_horiz</Icon>;
    case 'pdf':
      return <Icon {...rest}>picture_as_pdf</Icon>;
    case 'local_shipping':
      return <Icon {...rest}>local_shipping</Icon>;
    case 'business':
      return <Icon {...rest}>business</Icon>;
    default:
      console.log('Unknow icon', icon);
      return <Icon {...rest}>{icon}</Icon>;
  }
};

const Icons = (props) => {
  const { type, ...rest } = props;

  switch (type) {
    case 'icon':
      return <IconIcons {...rest} />;
    case 'svg':
    default:
      return <SvgIcons {...rest} />;
  }
};

export default Icons;
