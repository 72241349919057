import * as actionTypes from '../actions/ActionTypes';

const initialState = {
  filters: null
};

const setFilters = (state, payload) => ({...state, filters: payload});
const resetFilters = state => ({...state, filters: null});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTERS: return setFilters(state, action.payload);
    case actionTypes.RESET_FILTERS: return resetFilters(state);
    default:
      return state;
  }
};

export default reducer;
