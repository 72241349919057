import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from '../../axios-instance';
import * as actions from '../../store/actions';

class Logout extends Component {
  componentDidMount() {
    this.props.onLogout();
  }

  render() {
    if (this.props.isAuthenticated) {
      return null;
    }
    return <Redirect to="/" />;
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.token !== null,
});

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(actions.logout(axios))
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
