import React from 'react';
import { Switch, FormControlLabel, FormGroup, FormLabel, FormControl} from '@material-ui/core';


const switches = (props) => {
  const {
    label, showLabel, classes, value, onChange, allowed_values, color
  } = props;

  let switchs = allowed_values.map((item, idx) => (
    <FormControlLabel
      key={`sw${item.label}${item.value}${idx}`}
      control={
        <Switch
          checked={value.indexOf(item.value) !== -1}
          onChange={e => onChange(e, item.value)}
          value={item.value}
          color={color}
          className={classes.input}
        />
      }
      label={item.label}
    />
  ));

  if (switchs.length > 1) {
    switchs = (
      <FormGroup>
        {switchs}
      </FormGroup>
    );
  }

  if (label && showLabel) {
    return (
      <FormControl fullWidth>
        <FormLabel component="legend" className={classes.label}>{label}</FormLabel>
        {switchs}
      </FormControl>
    );
  }
  return (
    <FormControl fullWidth>
      {switchs}
    </FormControl>
  );
};

export default switches;
