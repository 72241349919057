import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Route, Switch, Redirect } from 'react-router-dom';

// = styles =
// 3rd
import 'styles/bootstrap.scss';
// custom
import 'styles/layout.scss';
import 'styles/theme.scss';
import 'styles/ui.scss';
import 'styles/app.scss';

import MainApp from '../MainApp/MainApp';
import PageLogin from '../Auth/Login';
import PageLogout from '../Auth/Logout';
import { authCheck } from '../../store/actions';
import axios from '../../axios-instance';
import AxiosErrorHandler from '../../hoc/AxiosErrorHandler/AxiosErrorHandler';

import Page404 from '../404/404';

class App extends Component {

  componentDidMount() {
    this.props.checkAuth();
  }

  render() {
    const {
      layoutBoxed, navCollapsed, navBehind, fixedHeader, sidebarWidth, theme, isCheckingAuth
    } = this.props;

    let routes = (
      <Switch>
        <Route path="/404" component={Page404} />
        <Route path="/" exact component={PageLogin} />
        <Route component={() => <Redirect to="/" />} />
      </Switch>
    );

    if (this.props.isLogged) {
      routes = (
        <Switch>
          <Route path="/404" component={Page404} />
          <Route path="/logout" component={PageLogout} />
          <Route path="/" component={MainApp} />
        </Switch>
      );
    }

    if (isCheckingAuth || isCheckingAuth === null) {
      return (
        <div id="app-inner">
          <div className="preloaderbar active"><span className="bar" /></div>
        </div>
      );
    }

    return (
      <div id="app-inner">
        <div className="preloaderbar hide"><span className="bar" /></div>
        <div className={classnames('app-main full-height', {
          'fixed-header': fixedHeader,
          'nav-collapsed': navCollapsed,
          'nav-behind': navBehind,
          'layout-boxed': layoutBoxed,
          'theme-gray': theme === 'gray',
          'theme-dark': theme === 'dark',
          'sidebar-sm': sidebarWidth === 'small',
          'sidebar-lg': sidebarWidth === 'large'
        })
        }>
          {routes}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  layoutBoxed: state.settings.layoutBoxed,
  navCollapsed: state.settings.navCollapsed,
  navBehind: state.settings.navBehind,
  fixedHeader: state.settings.fixedHeader,
  sidebarWidth: state.settings.sidebarWidth,
  theme: state.settings.theme,
  isLogged: state.auth.token !== null,
  isCheckingAuth: state.auth.checking,
  serverError: state.auth.chekingError
});

const mapDispatchToProps = dispatch => ({
  checkAuth: () => dispatch(authCheck(axios))
});

export default AxiosErrorHandler(connect(mapStateToProps, mapDispatchToProps)(App), axios, true);
