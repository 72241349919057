import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Select, { createFilter } from 'react-select';
// import { VariableSizeList as List } from 'react-window';
import { List } from 'react-virtualized';
import Creatable from 'react-select/creatable';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { TextValidator } from 'react-material-ui-form-validator';
import { TextField } from '@material-ui/core';
import ModalForm from 'components/Modals/ModalForm';
import getValidators from '../../../../../utils/validators';

const ITEM_HEIGHT = 48;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 100,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 0%',
    overflow: 'hidden',
    flexWrap: 'wrap',
    position: 'relative',
    boxSizing: 'border-box'
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    fontSize: 16,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
});

const NoOptionsMessage = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const inputComponent = ({ inputRef, ...props }) => (<div ref={inputRef} {...props} />);

const Control = (props) => {
  let ControlComponent = TextField;
  let ControlComponentProps = {};
  if (props.selectProps.validator) {
    ControlComponent = TextValidator;
    ControlComponentProps = props.selectProps.validator;
  }
  return (
    <ControlComponent
      {...ControlComponentProps}
      fullWidth
      disabled={props.isDisabled}
      margin="dense"
      InputLabelProps={{
        shrink: props.isFocused || props.hasValue,
      }}
      value={props.selectProps.validatorValue}
      label={props.selectProps.label}
      name={props.selectProps.name}
      error={props.selectProps.error}
      helperText={props.selectProps.helperText}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          ref: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
    />
  );
};

const Option = props => (
  <MenuItem
    key={`${props.type}-${props.value}`}
    buttonRef={props.innerRef}
    selected={props.isFocused}
    component="div"
    style={{
      fontWeight: props.isSelected ? 500 : 400,
    }}
    {...props.innerProps}
  >
    {props.children}
  </MenuItem>
);

const Placeholder = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.placeholder}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const SingleValue = props => (
  <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
    {props.children}
  </Typography>
);

const ValueContainer = props => <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;

const MultiValue = props => (
  <Chip
    tabIndex={-1}
    label={props.children}
    className={classNames(props.selectProps.classes.chip, {
      [props.selectProps.classes.chipFocused]: props.isFocused,
    })}
    onDelete={(event) => {
      props.removeProps.onClick();
      props.removeProps.onMouseDown(event);
    }}
  />
);

const Menu = props => (
  <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
    {props.children}
  </Paper>
);

class MenuList extends React.Component {

  static getDerivedStateFromProps(props, state) {
    const newState = {...state};
    newState.rows = props.children.length ? props.children : [props.children];
    newState.childs = 0;
    newState.sizes = newState.rows.map((row) => {
      let height = ITEM_HEIGHT;
      if (row.props && row.props.children && Array.isArray(row.props.children) && row.props.children.length) {
        height = ((ITEM_HEIGHT - 2) * row.props.children.length) + 26; // group title 20 + padding 8-8
        newState.childs += row.props.children.length;
      } else {
        newState.childs++;
      }
      return height;
    });
    newState.curheight = newState.sizes.reduce((cur, h) => cur + h, 8);
    return newState;
  }

  state = {};

  getItemCount = () => {
    this.rows.reduce((cur, row) => {
      if (row && row.props && row.props.children && Array.isArray(row.props.children) && row.props.children.length) {
        return cur + row.props.children.length;
      }
      return cur + 1;
    }, 0);
  }

  getItemHeight = index => this.state.sizes[index];

  rowRenderer = props => (
    <div
      key={`${this.props.selectProps.id}-row-${props.index}`}
      style={props.style}
    >
      {this.state.rows[props.index]}
    </div>
  );
  rowRenderer2 = props => (
    <div style={props.style} key={props.key}>{this.state.rows[props.index]}</div>
  );

  listRef = React.createRef();

  render() {
    const styles = this.props.selectProps.styles.menuList();
    const height = Math.max(styles.minHeight, Math.min(styles.maxHeight, (this.state.curheight)));
    const element = document.getElementById(this.props.selectProps.id);
    const rect = element.getBoundingClientRect();

    return (
      <List
        key={`${this.props.selectProps.id}-virtual-${this.state.childs}`}
        width={rect.width}
        height={height}
        itemCount={this.state.rows.length}
        itemSize={this.getItemHeight}
        rowCount={this.state.rows.length}
        rowHeight={({index}) => this.getItemHeight(index)}
        rowRenderer={this.rowRenderer2}
        ref={this.listRef}
      >
        {this.rowRenderer}
      </List>
    );
  }
}

const componentsM = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  MenuList
};

const compareOption = (inputValue, option) => {
  const candidate = inputValue.toLowerCase();
  return (
    `${option.value}`.toLowerCase() === candidate ||
    `${option.label}`.toLowerCase() === candidate
  );
};

const builtins = {
  formatCreateLabel: inputValue => `Ajouter "${inputValue}"`,
  isValidNewOption: (inputValue, selectValue, selectOptions) =>
    !(
      !inputValue ||
      selectValue.some(option => compareOption(inputValue, option)) ||
      selectOptions.some(option => compareOption(inputValue, option))
    )
};

class AutoComplete extends React.Component {

  state = {
    creating: false,
    createLabel: null
  }

  getValue = () => {
    const value = this.props.allowed_values.reduce(
      (cur, option) => (!cur && option.value === this.props.value ? option : cur)
      , null
    );

    if (value === null && this.props.value !== null && this.props.raw_attributes && this.props.raw_attributes.creatable) {
      return { value: this.props.value, label: this.props.value };
    }

    return value;
  }

  getValues = () => this.props.allowed_values.reduce((cur, option) => {
    if (option.options) {
      option.options.map((nested) => {
        if (this.props.value && this.props.value.indexOf(nested.value) !== -1) {
          cur.push({ ...nested });
        }
        return null;
      });
    } else if (option.value && this.props.value && this.props.value.indexOf(option.value) !== -1) {
      cur.push({ ...option });
    }
    return cur;
  }, []);

  getMenuMaxHeight = () => {
    const responsiveMaxHeight = window.innerHeight - (this.menuPortalTop + 10);
    const maxHeight = (Math.min(5 * ITEM_HEIGHT) + 8);

    return Math.min(responsiveMaxHeight, maxHeight);
  }

  getItemsCount = () => this.props.allowed_values.reduce((cur, value) => {
    if (value.options) {
      return cur + (value.options.length);
    }
    return cur + 1;
  }, 0);

  defaultStringify = (option) => {
    if (option.data.filter) {
      return `${option.data.filter} ${option.label} ${option.value}`;
    }
    return `${option.label} ${option.value}`;
  };

  handleChange = (value) => {
    let val = value;
    if (value === null || value.length === 0) {
      val = this.props.multiple ? [] : '';
    } else if (value.value) {
      val = value.value;
    }
    const event = { target: { value: this.props.multiple ? value.map(obj => obj.value) : val } };
    this.props.onChange(event);
  };

  handleCreate = (data) => {
    if (this.props.raw_attributes && this.props.raw_attributes.creatable && this.props.raw_attributes.creatable.action) {
      // On a un formulaire pour l'ajout, affiche la modal avec le formulaire
      this.setState({ creating: true, createLabel: data });
    } else if (this.props.raw_attributes && this.props.raw_attributes.creatable) {
      if (this.props.multiple) {
        this.props.form.addAllowedValue({ label: data, value: data }, this.props.name);
      } else {
        this.handleChange(data);
      }
    }
  }

  handleCreateClose = () => {
    this.setState({ creating: false, createLabel: null });
  }

  handleCreateSuccess = (rep) => {
    if (this.props.raw_attributes && this.props.raw_attributes.creatable && this.props.raw_attributes.creatable.label) {
      if (this.props.multiple) {
        // On est dans un champ multiple, il faut donc ajouter les nouvelles valeurs aux valeurs actuelles
        const currentValue = this.getValues();
        if (rep.result_id && Array.isArray(rep.result_id)) {
          // On a ajouter plusieurs elements d'un coup
          rep.result_id.map((value) => {
            // on ajoute chaque nouvel element à la valeur courante
            currentValue.push({ value });
            // Ajout de la valeur autorisé si on est pas en async
            if (!this.props.onCreate) {
              this.props.form.addAllowedValue({ label: value, value }, this.props.name);
            }
            return null;
          });
          // Si on est en async, Reload les allowed values, set les valeurs en callback
          if (this.props.onCreate) {
            this.props.onCreate(() => this.handleChange(currentValue));
          } else {
            this.handleChange(currentValue);
          }
        } else if (rep.result_id) {
          // On a ajouté un seul élément dans un multiple
          if (!this.props.onCreate) {
            this.props.form.addAllowedValue({ label: rep[this.props.raw_attributes.creatable.label], value: rep.result_id }, this.props.name);
          } else {
            currentValue.push({ value: rep.result_id });
            this.props.onCreate(() => this.handleChange(currentValue));
          }
        }
      } else if (this.props.onCreate) {
        this.props.onCreate(() => this.handleChange({ value: rep.result_id }));
      } else {
        this.props.form.addAllowedValue({ label: rep[this.props.raw_attributes.creatable.label], value: rep.result_id }, this.props.name);
      }
    }
    this.setState({ creating: false, createLabel: null });
  }

  menuPortalTop = 0;

  render() {
    const {
      allowed_values: allowedValues, classes,
      validators, value, multiple, onChange,
      raw_attributes: rawAttributes, ...rest
    } = this.props;


    if (this.state.creating && rawAttributes && rawAttributes.creatable && rawAttributes.creatable.action) {
      let formValues = { [rawAttributes.creatable.label]: this.state.createLabel };
      if (this.props.options_params) {
        formValues = this.props.options_params.reduce((curr, param) => {
          const currentParams = { ...curr };
          currentParams[param] = this.props.form.getFieldValue(param);
          return currentParams;
        }, formValues);
      }
      return (
        <ModalForm
          open
          title={`Ajout ${this.props.label}`}
          formURL={rawAttributes.creatable.form_url}
          formValues={formValues}
          requestPayload={rawAttributes.creatable}
          onClose={this.handleCreateClose}
          onSuccess={this.handleCreateSuccess}
        />
      );
    }

    const validatorProps = validators ? getValidators(validators, this.props) : null;
    let SelectCompoment = Select;

    if (rawAttributes && rawAttributes.creatable) {
      SelectCompoment = Creatable;
    }
    return (
      <SelectCompoment
        classes={classes}
        options={allowedValues}
        components={componentsM}
        styles={{
          menuPortal: (base) => { this.menuPortalTop = base.top; return { ...base, zIndex: 1300 }; },
          menuList: base => ({
            ...base,
            minHeight: (8 + ITEM_HEIGHT),
            maxHeight: this.getMenuMaxHeight()
          })
        }}
        value={multiple ? this.getValues() : this.getValue()}
        menuPortalTarget={document.body}
        validatorValue={value}
        onChange={this.handleChange}
        onCreateOption={this.handleCreate}
        placeholder={null}
        isMulti={multiple}
        validator={validatorProps}
        filterOption={createFilter({ stringify: this.defaultStringify })}
        {...rest}
        {...builtins}
      />
    );
  }
}

export default withStyles(styles)(AutoComplete);
