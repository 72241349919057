import React, { Component } from 'react';
import AutoComplete from './AutoComplete';
import axios from '../../../../../axios-instance';

class AsyncSelect extends Component {

  state = { isLoading: false, options: [] }

  componentDidMount() {
    if (this.props.value && this.state.options.length === 0) {
      this.loadOptions(true);
    }
  }

  componentDidUpdate() {
    if (!this.state.isLoading) {
      this.loadOptions();
    }
  }

  cache = {};
  lastRequest = '';

  isValidValue = () => {
    if (this.props.value && !this.props.multiple) {
      return this.state.options.reduce((curr, option) => {

        if (option.options && option.options.reduce((res, opt) => res || opt.value === this.props.value, false)) {
          return true;
        } else if (option.value === this.props.value) {
          return true;
        }
        return curr;
      }, false);
    } else if (this.props.value && this.props.multiple) {
      return this.props.value.reduce((cur, value) => {
        if (this.state.options.reduce((curr, option) => {
          if (option.options && option.options.reduce((res, opt) => res || opt.value === value, false)) {
            return true;
          } else if (option.value === value) {
            return true;
          }
          return curr;
        }, false)) {
          return true;
        }
        return cur;
      }, false);
    }
    return true;
  }

  loadOptions = (ignoreValue, callback) => {
    if (!this.props.options_url) {
      return;
    }

    if (ignoreValue !== true && !this.isValidValue()) {
      const event = { target: { value: null } };
      this.props.onChange(event);
      return;
    }

    const params = this.props.options_params ? this.props.options_params.reduce((curr, param) => {
      const currentParams = { ...curr };
      currentParams[param] = this.props.form.getFieldValue(param);
      return currentParams;
    }, {}) : {};

    params.idfield = this.props.name;
    params.action = this.props.form.getId();

    if (JSON.stringify(params) === this.lastRequest) {
      return;
    }

    this.lastRequest = JSON.stringify(params);

    if (this.cache[this.lastRequest]) {
      this.setState({ options: this.cache[this.lastRequest] });
      return;
    }

    this.setState({ isLoading: true });

    axios.post(this.props.options_url, params).then((response) => {
      const options = Object.keys(response.data.response).map(item => (response.data.response[item]));
      this.setState({ isLoading: false, options });
      this.cache[this.lastRequest] = options;
      if (callback) {
        callback();
      }
    }).catch((_) => {
      this.setState({ isLoading: false, options: [] });
    });
  };

  handleCreate = (callback) => {
    if (this.props.raw_attributes && this.props.raw_attributes.creatable) {
      this.cache = [];
      this.lastRequest = '';
      this.loadOptions(true, callback);
    }
  }

  render() {
    if (this.props.value && this.state.options.length === 0) {
      return null;
    }
    const {
      options_url, allowed_values, ...rest
    } = this.props;

    const enable = this.props.options_params ? this.props.options_params.reduce((curr, param) => {
      if (curr && (!this.props.form.getFieldValue(param) || this.props.form.getFieldValue(param).length === 0)) {
        return false;
      }
      return curr;
    }, true) : true;

    const {
      isLoading,
      options,
    } = this.state;

    return (
      <AutoComplete
        allowed_values={options}
        isLoading={isLoading}
        onMenuOpen={this.loadOptions}
        onCreate={this.handleCreate}
        isDisabled={!enable}
        {...rest}
      />
    );
  }
}

export default AsyncSelect;
