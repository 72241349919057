import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import containers from '../containers';

const AbstractContainer = (props) => {
  const {component} = props.match.params;

  if (component && containers[component] && containers[component].component) {
    const path = containers[component].route ? containers[component].route : '/';
    return <Route path={path} component={containers[component].component} />;
  }

  return <Redirect to="/404" />;
};

export default AbstractContainer;
