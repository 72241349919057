import * as actionTypes from '../actions/ActionTypes';

const initialState = {
  idimport: null
};

const setImport = (state, payload) => ({...state, idimport: payload});
const resetImport = state => ({...state, idimport: null});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_IMPORT: return setImport(state, action.payload);
    case actionTypes.RESET_IMPORT: return resetImport(state);
    default:
      return state;
  }
};

export default reducer;
