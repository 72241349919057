import React, { Component } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Typography, withMobileDialog, Button, IconButton
} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import axios from '../../axios-instance';
import ModalLoading from './ModalLoading';
import Form from '../UI/Form/Form';
import ModalError from './ModalError';

const styles = theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  div: {
    marginBottom: theme.spacing.unit * 2,
  },
  label: {
    paddingLeft: theme.spacing.unit * 2,
  }
});

class ModalForm extends Component {

  constructor(props) {
    super(props);
    this.state = { ...this.state };
    if (!props.formValuesURL && props.formDefinition) {
      this.state = { ...this.state, form: { ...props.formDefinition } };
    }
  }

  componentDidMount() {
    if (!this.props.formValuesURL && this.props.formURL && this.props.open && !this.state.form) {
      axios.post(this.props.formURL, this.props.requestPayload).then((rep) => {
        const form = { ...rep.data.response };
        if (this.props.formValues) {
          Object.keys(form.fields).map((field) => {
            if (this.props.formValues[field]) {
              if (form.fields[field].name.substring(form.fields[field].name.length - 2) === '[]') {
                form.fields[field].value = [this.props.formValues[field]];
              } else {
                form.fields[field].value = this.props.formValues[field];
              }
              if (form.fields[field] && form.fields[field].allowed_values
                && form.fields[field].raw_attributes && form.fields[field].raw_attributes.creatable) {
                const isNew = form.fields[field].allowed_values.reduce((res, value) => {
                  if (!res || (this.props.formValues[field].value === value.value && this.props.formValues[field].label === value.label)) {
                    return false;
                  }
                  return res;
                }, true);
                if (isNew) {
                  form.fields[field].allowed_values.push({ label: this.props.formValues[field], value: this.props.formValues[field] });
                }
              }
            }
            return null;
          });
        }
        this.setState({ form });
      }).catch((error) => {
        console.warn(error);
        this.setState({ error: error.response.data });
      });
    }
    if (this.props.formValuesURL && this.props.open && this.props.formDefinition) {
      axios.post(this.props.formValuesURL, this.props.requestPayload).then((rep) => {
        const form = { ...this.props.formDefinition };
        const fields = { ...form.fields };

        Object.keys(form.fields).map((field) => {
          fields[field] = { ...form.fields[field], value: rep.data.response[field] };
          return null;
        });

        this.setState({ form: { ...form, fields } });
      }).catch((error) => {
        this.setState({ error: error.response.data });
      });
    }
  }

  onClose = () => {
    this.setState({ form: null });
    this.props.onClose();
  }

  onSubmit = (datas, fields) => {
    this.setState({ posting: true, form: { ...this.state.form, fields: { ...fields } } });
    axios.post(this.state.form.action, datas).then((rep) => {
      this.setState({ posting: false });
      const result = {
        ...datas,
        ...rep.data.response
      };
      this.props.onSuccess(result);
    }).catch((error) => {
      if (!error.response) {
        console.warn(error);
      }
      this.setState({ formError: error.response.data, posting: false });
    });
  }

  render() {
    const {
      fullScreen, open, classes, loading, title
    } = this.props;

    const {
      error, form, posting, formError
    } = this.state;

    if (error && open) {
      return <ModalError error={error} handleClose={this.onClose} />;
    }
    if (open && (!form || posting)) {
      return <ModalLoading />;
    }

    return (
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        PaperProps={{
          style: {
            minWidth: '25%'
          }
        }}
        maxWidth="md"
        open={open}
        onClose={this.onClose}
        aria-labelledby="form-dialog-title"
        TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
      >
        <DialogTitle id="form-dialog-title" disableTypography>
          <Typography variant="h6">{title}</Typography>
          <IconButton aria-label="Close" className={classes.closeButton} onClick={this.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="px-2">
          {formError && (formError.code === 'KO' || formError.code === 'KO-VALIDATION') ?
            <Typography align="justify" variant="caption" paragraph>
              {formError.message}
            </Typography>
            : null}
          <Form
            form={form}
            handleSubmit={this.onSubmit}
            ref={(ref) => { this.form = ref; }}
            className={loading ? 'd-none' : 'modal-form'}
            error={formError}
            classes={{label: classes.label, div: classes.div}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={e => this.form.clickSubmit(e)} color="primary">
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(ModalForm));
