import React from 'react';
import { Radio, FormControlLabel, RadioGroup } from '@material-ui/core';
import getValidators from '../../../../../utils/validators';
import FormControlValidator from './FormControlValidator';

const radio = (props) => {
  const {
    label, name, validators, classes, value, type, onChange, allowed_values, color, ...rest
  } = props;

  let radios = allowed_values.map((option, idx) => (
    <FormControlLabel
      key={`rd${option.label}${option.value}${idx}`}
      control={<Radio color={option.color ? option.color : color} />}
      label={option.label}
      value={option.value}
    />
  ));

  if (radios.length > 1) {
    radios = (
      <RadioGroup
        row
        onChange={onChange}
        value={value}
        {...rest}
      >
        {radios}
      </RadioGroup>
    );
  }
  const validatorProps = validators ? getValidators(validators, props) : {};

  return (
    <FormControlValidator
      classes={classes}
      value={value}
      name={name}
      label={label}
      {...validatorProps}
    >
      {radios}
    </FormControlValidator>
  );
};


export default radio;
