import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Dropzone from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Grid from '@material-ui/core/Grid';
import { convertBytesToMbsOrKbs } from './helpers';
import SnackbarContentWrapper from './SnackbarContentWrapper';
import PreviewList from './PreviewList';

const styles = theme => ({
  '@keyframes progress': {
    '0%': {
      backgroundPosition: '0 0',
    },
    '100%': {
      backgroundPosition: '-70px 0',
    },
  },
  dropZone: {
    position: 'relative',
    width: '100%',
    minHeight: '50px',
    backgroundColor: '#F0F0F0',
    border: 'dashed',
    borderColor: theme.palette.primary.light,
    cursor: 'pointer',
    boxSizing: 'border-box',
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover $uploadIconSize': {
      borderColor: theme.palette.primary.main,
    }
  },
  stripes: {
    border: 'solid',
    backgroundImage: 'repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)',
    animation: 'progress 2s linear infinite !important',
    backgroundSize: '150% 100%',
  },
  rejectStripes: {
    border: 'solid',
    backgroundImage: 'repeating-linear-gradient(-45deg, #fc8785, #fc8785 25px, #f4231f 25px, #f4231f 50px)',
    animation: 'progress 2s linear infinite !important',
    backgroundSize: '150% 100%',
  },
  dropzoneTextStyle: {
    textAlign: 'center'
  },
  uploadIconSize: {
    width: 51,
    height: 51,
    color: theme.palette.primary.light
  },
  dropzoneParagraph: {
    fontSize: 24
  }
});


class DropzoneArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileObjects: this.props.value ? [this.props.value] : [],
      openSnackBar: false,
      snackbarMessage: '',
      snackbarVariant: 'success'
    };
  }

  componentWillUnmount() {
    if (this.props.clearOnUnmount) {
      this.setState({
        fileObjects: []
      });
    }
  }
  onDrop = (files) => {
    if (this.state.fileObjects.length + files.length > this.props.filesLimit) {
      this.setState({
        openSnackBar: true,
        snackbarMessage: `Nombre maximal de fichiers autorisé dépassé. seulement ${this.props.filesLimit} autorisé.`,
        snackbarVariant: 'error'
      });
    } else {
      let count = 0;
      let message = '';
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          let encoded = reader.result.replace(/^data:(.*;base64,)?/, '');
          if ((encoded.length % 4) > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4));
          }
          this.setState({
            fileObjects: this.state.fileObjects.concat({ file, data: event.target.result, base64: encoded})
          }, () => {
            if (this.props.onChange) {
              this.props.onChange(this.state.fileObjects.map(fileObject => fileObject.base64));
            }
            if (this.props.onDrop) {
              this.props.onDrop(file);
            }
            message += `Fichier ${file.name} envoyé avec succès. `;
            count++; // we cannot rely on the index because this is asynchronous
            if (count === files.length) {
              // display message when the last one fires
              this.setState({
                openSnackBar: true,
                snackbarMessage: message,
                snackbarVariant: 'success'
              });
            }
          });
        };
        reader.readAsDataURL(file);
      });
    }
  }
  onCloseSnackbar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  handleRemove = fileIndex => (event) => {
    event.stopPropagation();
    const { fileObjects } = this.state;
    const { file } = fileObjects.filter((fileObject, i) => i === fileIndex)[0];
    fileObjects.splice(fileIndex, 1);
    this.setState(fileObjects, () => {
      if (this.props.onDelete) {
        this.props.onDelete(file);
      }
      if (this.props.onChange) {
        this.props.onChange(this.state.fileObjects);
      }
      this.setState({
        openSnackBar: true,
        snackbarMessage: (`Fichier ${file.name ? file.name : ''} supprimé`),
        snackbarVariant: 'info'
      });
    });
  }
  handleDropRejected = (rejectedFiles, evt) => {
    let message = '';
    rejectedFiles.forEach((rejectedFile) => {
      message = `Le fichier ${rejectedFile.name} a été rejeté. `;
      if (!this.props.acceptedFiles.includes(rejectedFile.type)) {
        message += 'Type de fichier non supporté. ';
      }
      if (rejectedFile.size > this.props.fileSizeLimit) {
        message += `Trop volumineux. Taille limite ${convertBytesToMbsOrKbs(this.props.fileSizeLimit)}. `;
      }
    });
    if (this.props.onDropRejected) {
      this.props.onDropRejected(rejectedFiles, evt);
    }
    this.setState({
      openSnackBar: true,
      snackbarMessage: message,
      snackbarVariant: 'error'
    });
  }
  render() {
    const { classes } = this.props;
    const showPreviews = this.props.showPreviews && this.state.fileObjects.length > 0;
    const showPreviewsInDropzone = this.props.showPreviewsInDropzone && this.state.fileObjects.length > 0;
    return (
      <Fragment>
        <Dropzone
          accept={this.props.acceptedFiles.join(',')}
          onDrop={this.onDrop}
          onDropRejected={this.handleDropRejected}
          className={classes.dropZone}
          acceptClassName={classes.stripes}
          rejectClassName={classes.rejectStripes}
          maxSize={this.props.maxFileSize}
        >
          {this.state.fileObjects && this.state.fileObjects.length === 0 ?
            <div className={classes.dropzoneTextStyle}>
              <CloudUploadIcon className={classes.uploadIconSize} />
            </div>
            : null
          }
          {showPreviewsInDropzone &&
            <PreviewList
              fileObjects={this.state.fileObjects}
              handleRemove={this.handleRemove}
            />
          }
        </Dropzone>
        {showPreviews &&
          <Fragment>
            <Grid container>
              <span>Preview:</span>
            </Grid>
            <PreviewList
              fileObjects={this.state.fileObjects}
              handleRemove={this.handleRemove}
            />
          </Fragment>
        }
        {this.props.showAlerts &&
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.openSnackBar}
            autoHideDuration={6000}
            onClose={this.onCloseSnackbar}
          >
            <SnackbarContentWrapper
              onClose={this.onCloseSnackbar}
              variant={this.state.snackbarVariant}
              message={this.state.snackbarMessage}
            />
          </Snackbar>
        }
      </Fragment>
    );
  }
}

DropzoneArea.defaultProps = {
  acceptedFiles: ['image/*', 'video/*', 'application/*'],
  filesLimit: 1,
  maxFileSize: 3000000,
  showPreviews: false, // By default previews show up under in the dialog and inside in the standalone
  showPreviewsInDropzone: true,
  showAlerts: true,
  clearOnUnmount: true,
  onChange: () => { },
  onDrop: () => { },
  onDropRejected: () => { },
  onDelete: () => { }
};
DropzoneArea.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  acceptedFiles: PropTypes.array,
  filesLimit: PropTypes.number,
  maxFileSize: PropTypes.number,
  showPreviews: PropTypes.bool,
  showPreviewsInDropzone: PropTypes.bool,
  showAlerts: PropTypes.bool,
  clearOnUnmount: PropTypes.bool,
  onChange: PropTypes.func,
  onDrop: PropTypes.func,
  onDropRejected: PropTypes.func,
  onDelete: PropTypes.func
};
export default withStyles(styles)(DropzoneArea);
