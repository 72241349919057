import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import settings from './settings';
import auth from './auth';
import axios from './axios';
import filters from './filters';
import imports from './imports';

const reducers = {
  routing: routerReducer,
  settings,
  auth,
  axios,
  filters,
  imports
};

export default combineReducers(reducers);
