import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import APPCONFIG from 'constants/Config';
import NavLeftList from './NavLeftList';
import NavRightList from './NavRightList';


class Header extends React.Component {
  onToogleMobileMenu = (e) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-open');
  }

  render() {
    const { isFixedHeader, colorOption } = this.props;

    return (
      <section className="app-header">
        <div
          className={classnames('app-header-inner', {
            'bg-color-light': ['11', '12', '13', '14', '15', '16', '21'].indexOf(colorOption) >= 0,
            'bg-color-dark': colorOption === '31',
            'bg-color-primary': ['22', '32'].indexOf(colorOption) >= 0,
            'bg-color-success': ['23', '33'].indexOf(colorOption) >= 0,
            'bg-color-info': ['24', '34'].indexOf(colorOption) >= 0,
            'bg-color-warning': ['25', '35'].indexOf(colorOption) >= 0,
            'bg-color-danger': ['26', '36'].indexOf(colorOption) >= 0
          })}
        >
          <div className="d-flex justify-content-between">
            <div className="d-lg-none d-xl-none float-left">
              <button href="#" className="md-button header-icon toggle-sidebar-btn" onClick={this.onToogleMobileMenu}>
                <i className="material-icons">menu</i>
              </button>
            </div>
            <div className="top-nav-left d-none d-lg-inline-flex d-xl-inline-flex" style={{maxWidth: '90%', flexGrow: 1}}>
              {isFixedHeader ? (
                <div className="brand">
                  <h2><Link to="/">{APPCONFIG.brandLogo && APPCONFIG.brandLogo.small ? <img alt="logo" style={{height: 41}} src={APPCONFIG.brandLogo.small} /> : APPCONFIG.brand}</Link></h2>
                </div>
              ) : null }
              <NavLeftList />
            </div>
            <div className="top-nav-right">
              <NavRightList />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  colorOption: state.settings.colorOption,
  navCollapsed: state.settings.navCollapsed,
  isFixedHeader: state.settings.isFixedHeader
});

export default connect(mapStateToProps)(Header);

