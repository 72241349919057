import React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { FormLabel, FormControl, FormHelperText } from '@material-ui/core';

class FormControlValidator extends ValidatorComponent {

  errorText() {
    const { isValid } = this.state;
    if (isValid) {
      return null;
    }

    return (<FormHelperText>{this.getErrorMessage()}</FormHelperText>);
  }

  render() {
    const { classes, label, error, children } = this.props;

    const { isValid } = this.state;

    if (label) {
      return (
        <FormControl required={this.props.required} error={!isValid || error} fullWidth className={classes.input} margin="dense" >
          <FormLabel component="legend" className={classes.label}>{label}</FormLabel>
          {children}
          {this.errorText()}
        </FormControl>
      );
    }
    return (
      <FormControl error={!isValid || error} fullWidth margin="dense">
        {children}
        {this.errorText()}
      </FormControl>
    );
  }
}

export default FormControlValidator;
